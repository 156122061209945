import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import { Button } from '@costain/react-ui'
import LocationMarker from './LocationMarker'

const ConfirmAddress = ({
  center,
  index,
  setIndex,
  setCenter,
  postLoc,
  isMobile,
  relative,
  onConfirm,
  confirming,
  mapWidth,
  setConfirmingAddress,
  setAddressConfirmed
}) => {
  const [currentCenter, setCurrentCenter] = useState([])

  const createMapOptions = () => {
    return {
      geatureHandling: 'greedy',
      fullscreenControl: false
    }
  }

  useEffect(() => {
    setCurrentCenter([center[1], center[0]])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div
      style={{
        width: mapWidth || '100vw',
        marginLeft: !mapWidth ? (isMobile ? '-18px' : '-104px') : '0px',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        height: '100%'
      }}
    >
      <p>Drag the map</p>
      <GoogleMapReact
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        gestureHandling
        bootstrapURLKeys={{ key: 'AIzaSyDsRCLlungAv9WxGYD8SsY8mUKj-PduBl0' }}
        center={center}
        onChange={(x) => setCurrentCenter([x.center.lng, x.center.lat])}
        defaultZoom={17}
      >
        <LocationMarker lat={currentCenter[1]} lng={currentCenter[0]} />
      </GoogleMapReact>
      <Button
        loading={confirming}
        onClick={() => {
          setCenter([currentCenter[0], currentCenter[1]])
          setConfirmingAddress(false)
          setAddressConfirmed(true)
        }}
        title='Confirm'
        style={{
          position: relative ? 'relative' : 'absolute',
          bottom: isMobile ? '20px' : '80px',
          left: isMobile || relative ? 0 : '10%',
          right: isMobile ? 0 : 'unset',
          width: '162px'
        }}
      />
    </div>
  )
}

export default ConfirmAddress
