import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import getActivityIcon from '../../utils/getActivityIcon'
import getStatusColor from '../../utils/getStatusColor'
import { DateTime } from 'luxon'
import getStatus from '../../utils/getStatus'
import isBooked from '../../utils/isBooked'
import getReadableActivity from '../../utils/getReadableActivity'
import SmallChip from '../../components/SmallChip'
import {css} from 'glamor'

function OpportunityCard ({ op, hovering, setHovering, own, user, style, interactive, isMobile }) {
  const [diff, setDiff] = useState(null)
  const [booked, setBooked] = useState(null)
  const [localOp, setLocalOp] = useState(null)
  useEffect(() => {
    const x = { ...op, startDate: DateTime.fromISO(op.startDate), endDate: DateTime.fromISO(op.endDate) }
    console.log(x)
    setLocalOp(x)

    const getDiff = () => {
      console.log('Getting diff')
      const d = x.endDate.diff(x.startDate, ['hours', 'minutes']).toObject()
      return d.hours > 0 ? `${parseInt(d.hours)}h` : `${parseInt(d.minutes)}m`
    }
    const book = isBooked(user, op)
    const d = getDiff()
    setDiff(d)
    setBooked(book)
  }, [op, user])

  const { push } = useHistory()

  // useEffect(() => {
  //   const getCreatedBy = async() => {
  //     if (uuidValidate(opportunity.createdBy)) {
  //       let user = await getUser(graph,opportunity.createdBy)
  //       setCreatedBy(user.displayName)
  //     } else {
  //       setCreatedBy(opportunity.createdBy)
  //     }
  //   }
  //   getCreatedBy()
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // },[opportunity])

  return (
    <>
      {localOp && (
        <div
          {...css({
            backgroundColor: '#fff',
            minHeight: '52px',
            marginBottom: '5px',
            display: 'flex',
            alignItems: 'center',
            padding: '8px',
            cursor: interactive ? 'pointer' : 'initial',
            border: '1px solid rgb(247,247,247)',
            borderRadius: '10px',
            ':hover': {
              boxShadow: interactive ? '2px 2px 2px 2px #fbfbfb' : 'none',
            },
            ...style
          })}
          onClick={() => interactive ? push(`/opportunities/${op._id}`): {}}
          onMouseEnter={() => interactive ? setHovering(op._id) : {}}
          onMouseLeave={() => interactive ? setHovering(null): {}}
        >
          <div style={{ flex: 7 }}>
            <p style={{ margin: 0, marginBottom: '6px' }}>{isMobile ? (`${getActivityIcon(op.activity[0])} `) :''}{op.title}</p>
            <div style={{ display: 'flex', marginLeft: '2px', lineHeight: '12px' }}>
              {!isMobile && (
                <SmallChip img={getActivityIcon(op.activity[0])} value={`${getReadableActivity(op.activity[0])}`} style={{marginTop:'0px'}}/>
              )}

              <div style={{ fontSize: '11px' }}>
                {((localOp.endDate).startOf('day') <= (localOp.startDate).startOf('day')) && (
                  <>
                    <b style={{ fontWeight: 500 }}>{(localOp.startDate).toFormat('ccc dd/MM')}</b> ·  <b style={{ fontWeight: 500 }}>{(localOp.startDate).toFormat('HH:mm')} - {(localOp.endDate).toFormat('HH:mm')}</b> ({diff})
                  </>
                )}
                {((localOp.endDate).startOf('day') > (localOp.startDate).startOf('day')) && (
                  <>
                    <b style={{ fontWeight: 500 }}>{localOp.startDate.toFormat('ccc dd/MM')}</b>   <FontAwesomeIcon style={{ marginLeft: '5px', marginRight: '8px' }} size='xs' icon={faArrowRight} />
                    <b style={{ fontWeight: 500 }}>{localOp.endDate.toFormat('ccc dd/MM')}</b>
                  </>
                )}
              </div>

              {localOp.spacesRemaining && (DateTime.now() < localOp.endDate) && (
                <p style={{ margin: 0, fontSize: '10px', display: 'flex', alignItems: 'center', marginLeft: '5px' }}> · {localOp.spacesRemaining} spaces</p>
              )}
              {(!own && booked) && (
                <p style={{ margin: 0, marginLeft: '5px', fontSize: '10px', display: 'flex', alignSelf: 'center' }}>· 🎟️</p>
              )}
              {own && (
                <p style={{ margin: 0, marginLeft: '5px', fontSize: '10px', display: 'flex', alignSelf: 'center', color: getStatusColor(localOp, own) }}><span style={{color:'black'}}>·</span> {getStatus(booked, own, op)}</p>
              )}

              <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: '6px' }} />
            </div>
          </div>
        </div>

      )}

    </>

  )
}

export default OpportunityCard
