import React, { useEffect, useRef, useState } from 'react'
import Input from '../../components/Input'
import Label from '../../components/Label'
import { Select as MatSelect, Checkbox, ListItemText } from '@material-ui/core'
import { Button } from '@costain/react-ui'
import { useParams, useHistory } from 'react-router-dom'
import ChipInput from 'material-ui-chip-input'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import ChipUtils from '../../utils/ChipUtils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheckCircle,
  faPaperPlane,
  faTimesCircle,
  faUpload,
  faMinusCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'
import { validate as uuidValidate } from 'uuid'
import validateEmail from '../../utils/validateEmail'
import { makeStyles } from '@material-ui/core/styles'
import getBase64 from '../../utils/getBase64'
import { asyncForEach } from '@costain/utils'
import getUserPicture from '../../utils/getUserPicture'
import MenuItem from '@material-ui/core/MenuItem'

import TextArea from '../../components/TextArea'
// import 'react-day-picker/lib/style.css';
// import DateRangePicker from '../../components/DateRangePicker'
import DateRangePicker from '../../components/DateRangePicker'
import ConfirmAddress from '../../components/ConfirmAddress'
import colors from '../../components/_colors'
import { faSave } from '@fortawesome/free-regular-svg-icons'
import { useSnackbar } from 'notistack'
import getReadableActivity from '../../utils/getReadableActivity'
import getActivityIcon from '../../utils/getActivityIcon'
import RichInput from '../../components/RichInput'
import getUser from '../../utils/getUser'
import opTemplate from '../../assets/files/opportunity.xlsx'
import { readString } from 'react-papaparse'
import validateBulkUploadData from '../../utils/validateBulkUploadData'
import { DateTime } from 'luxon'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({

  chip: {
    paddingTop: '0px',
  },
  root: {
    height:'44px',
    maxWidth: '800px',
    width: '100%',
    boxShadow: 'rgb(232, 232, 233) 15px 4px 150px',
  },
  focused: {
    outline:`4px solid ${colors.highlightYellow} !important`,
    border: '2px solid black !important',
    borderRadius: '0px !important',
  },
  chipContainer : {
    height: "100%",
    border: '1px solid #F1F1F1',
    display: 'flex',
    outline: 'none',
    boxSizing: 'border-box',
    borderRadius: '5px',
    fontSize: '18px',
    backgroundColor: '#fff',
    lineHeight: '22px',
    color: 'rgba(0, 0, 0, 0.8)',
    '&:placeholder': {
      color: 'rgba(0, 0, 0, .5)'
    },
    boxShadow: 'rgb(232, 232, 233) 15px 4px 150px',
  },
  inputRoot: {
    height:'100%'
  },
  input: {
    height:'100%',
    padding: 0
  }
}))

function NewOpportunity ({ update, graph, api, graphProfile, user, isMobile }) {
  const classes = useStyles()
  const { id } = useParams()
  const { push, goBack } = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const [title, setTitle] = useState('')
  const [activity, setActivity] = useState(null)
  const [shortDescription, setShortDescription] = useState('')
  const [longDescription, setLongDescription] = useState('')
  const [contacts, setContacts] = useState([])
  const [contactChips, setContactChips] = useState([])
  const [contactSelect, setContactSelect] = useState(false)
  const [selectingContact, setSelectingContact] = useState(false)
  const [searchContacts, setSeachContacts] = useState([])
  const [supportingDocumentation, setSupportingDocumentation] = useState([])
  // eslint-disable-next-line
  const [uploadingDocs, setUploadingDocs] = useState(false)
  const [opportunitySaving, setOpportunitySaving] = useState(false)
  const [opportunityPublishing, setOpportunityPublishing] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [startDateValid, setStartDateValid] = useState(false)
  const [endDate, setEndDate] = useState(null)
  const [endDateValid, setEndDateValid] = useState(false)
  // const [dateFocused, setDateFocused] = useState(false)
  const [recurrence, setRecurrence] = useState('')
  const [activityUrl, setActivityUrl] = useState('')
  // eslint-disable-next-line
  const [activityLocation, setActivityLocation] = useState({})
  const [noSpaces, setNoSpaces] = useState(null)
  const [line1, setLine1] = useState('')
  const [line2, setLine2] = useState('')
  const [town, setTown] = useState('')
  const [postCode, setPostCode] = useState('')
  const [activityOnline, setActivityOnline] = useState(false)
  const [activityPhysical, setActivityPhysical] = useState(false)
  const [confirmingAddress, setConfirmingAddress] = useState(false)
  const [center, setCenter] = useState([])
  const [preparing, setPreparing] = useState(false)
  const [addressConfirmed, setAddressConfirmed] = useState(false)
  const [status, setStatus] = useState('DRAFT')
  const [op, setOp] = useState({})
  const [validationError, setValidationError] = useState('')
  const [bulkValidationError, setBulkValidationError] = useState('')

  const filesInput = useRef(null)
  const bulkInput = useRef(null)

  // const [feasibilityFactor, setFeasibilityFactor] = useState(0)
  // const [marketSizeFactor, setMarketSizeFactor] = useState(0)
  // const [efficiencyImprovementFactor, setEfficiencyImprovementFactor] = useState(0)
  // const [marketGrowthFactor, setMarketGrowthFactor] = useState(0)
  // const [futurePotentialFactor, setFuturePotentialFactor] = useState(0)
  // const [impactFactor, setImpactFactor] = useState(0)
  // const [solutionDifferentiationFactor, setSolutionDifferentiationFactor] = useState(0)
  // const [technicalCapabilityFactor, setTechnicalCapabilityFactor] = useState(0)
  // const [financeFactor, setFinanceFactor] = useState(0)
  // const [riskFactor, setRiskFactor] = useState(0)

  const confirmAddress = async () => {
    setPreparing(true)
    const noSpacePostCode = postCode.replace(/ /g, '')
    const coords = await api.get(`/geo/coords?postcode=${noSpacePostCode}`)
    // console.log(coords)
    if (coords.data) {
      setCenter(coords.data)
      setConfirmingAddress(true)
    }
    setPreparing(false)
  }

  useEffect(() => {
    // console.log('graphProfile', graphProfile)
    if (!update && graphProfile) handleAddContact(graphProfile.id)
    if (update && user) {
      const getOpportunity = async () => {
        const { data } = await api.get(`/opportunities/${id}`)
        if (data.createdBy._id !== user._id) goBack()
        console.log('opportunity', data)
        setOp(data)
        setStatus(data.status)
        setTitle(data.title)
        setSupportingDocumentation(data.supportingDocumentation)
        setShortDescription(data.shortDescription)
        setLongDescription(data.longDescription)
        setActivity(data.activity[0])
        setStartDate(DateTime.fromISO(data.startDate))
        setEndDate(DateTime.fromISO(data.endDate))
        setRecurrence(data.recurrence)
        if (data.activityLocation) {
          setActivityPhysical(true)
          setAddressConfirmed(true)
          setLine1(data.activityLocation.line1)
          setLine2(data.activityLocation.line2)
          setTown(data.activityLocation.town)
          setPostCode(data.activityLocation.postCode)
        }
        if (data.activityUrl) {
          setActivityOnline(true)
          setActivityUrl(data.activityUrl)
        }
        setActivityLocation(data.activityLocation)
        setNoSpaces(data.noSpaces)
        setSupportingDocumentation(data.supportingDocumentation)
        await initContacts(data.contacts)
      }
      getOpportunity()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphProfile, user])

  const initContacts = async (cntcs) => {
    // console.log(cntcs)
    let chips = []
    for (const own of cntcs) {
      console.log(own)
      if (own) {
        if (own.adId) {
          const pic = await getUserPicture({ id: own.adId }, api)
          // console.log(pic)
          own.pic = pic
          chips = ChipUtils.addChipItem(own, chips)
        } else {
          chips = ChipUtils.addChipItem(own, chips)
        }
        // console.log(chips)
        setContactChips(chips)
      }
    }
    setContacts(cntcs)
  }

  const onContactChange = async (e) => {
    setContactSelect(true)
    // console.log(e.target.value)
    if (e.target.value.length <= 2) return
    try {
      const res = await graph.get(`/users?$search="displayName:${e.target.value}"&$orderby=displayName&$count=true`, {
        headers: {
          ConsistencyLevel: 'eventual'
        }
      })
      const users = []
      for (const user of res.data.value) {
        users.push(user)
      }
      setSeachContacts(users)
    } catch (e) {
      console.log(e)
    }
  }
  // eslint-disable-next-line
  const uploadSupportingDocs = async (e) => {
    setUploadingDocs(true)
    const files = []
    const f = e.target.files
    console.log('e.target.files=', e.target.files)
    let docs = await getBase64(e.target.files)
    docs = docs.map(doc => doc.split(',')[1])
    await asyncForEach(f, async (file, i) => {
      const res = await api.post('/file', {
        filename: file.name,
        data: docs[i]
      })
      files.push({
        name: file.name,
        type: file.type,
        link: res.data.url
      })
    })
    console.log('files=', files)
    setSupportingDocumentation([...supportingDocumentation, ...files])
    setUploadingDocs(false)
  }

  const bulkUpload = async (e) => {
    setOpportunitySaving(true)

    if (e.target.files.length === 1) {
      const reader = new FileReader()
      reader.onload = async (e) => {
        const content = e.target.result

        readString(
          content,
          {
            delimiter: ',',
            skipEmptyLines: true,
            error: (error, file) => {
            //! display error if file failed to parse
              console.log('error in csv', error)
            },
            complete: async (results, file) => {
            //! skip first line
              if (results.data.length > 1) {
                results.data.shift()
              }
              console.log('csv results.data=', results.data)

              //! validate csv data
              var validation = await validateBulkUploadData(api, results.data)

              if (!validation || !validation.result) {
                // Clear the input
                bulkInput.current.value = null;
              //! display error if file is invalid
              // console.log('bulkUploadError=', validation.error);
                if (validation && validation.error) {
                  setBulkValidationError(validation.error)
                } else {
                  setBulkValidationError('Unknown')
                }
              } else {
                setBulkValidationError('')
                //! or process each line
                // TODO: this is inefficient...
                const x = await Promise.all(results.data.map(async record => {
                  return await publishOpportunityRecord(record)
                }))
                enqueueSnackbar('Opportunities uploaded')
                push('/opportunities?page=0&status=LIVE')
              }
            }
          })
      }
      reader.readAsText(e.target.files[0])
    }

    setOpportunitySaving(true)
  }

  const s = process.env.REACT_APP_ACTIVITY_TYPES.split(',')

  // const getUserByAadId = async(id) => {
  //     try {
  //       const {data} = await api.get(`/user/${id}`)
  //       return data
  //     } catch (e) {
  //       console.log(e)
  //     }

  // }

  const handleAddContact = async (contact) => {
    // console.log(contact)
    // console.log(contacts)
    setContactSelect(false)
    setSelectingContact(false)
    setSeachContacts([])

    if (contacts.includes(contact)) return
    // console.log(`Is valid uuid: ${uuidValidate(contact)}`)
    if (contact.includes('@')) {
      // console.log(validateEmail(contact))
      if (!validateEmail(contact)) return
      setContactChips(ChipUtils.addChipItem(contact, contactChips))
    }
    if (!contact.includes('@')) {
      if (!uuidValidate(contact)) return
      const user = await getUser({ id: contact }, api)
      const pic = await getUserPicture({ id: user.adId }, api)
      user.pic = pic
      // console.log(user)
      setContactChips(ChipUtils.addChipItem(user, contactChips))
    }
    // console.log(contacts)
    setContacts([...contacts, contact])
  }

  const resolvePhysicalAddress = async (locationString) => {
    var locations = locationString.split(' ')
    for (const location of locations) {
      if (location.startsWith('///')) {
        const physical = await api.get(`/geo/what3words/coords?words=${location.replace('///', '')}`)

        console.log('what3words physical=', physical)
        if (physical && physical.data) {
          const a = {
            line1: physical.data.nearestPlace,
            line2: '',
            town: '',
            postCode: '', //! TBD
            coords: [physical.data.coordinates.lng, physical.data.coordinates.lat]
          }
          return a
        } else {
          return null
        }
      }
    }
    return null
  }

  const resolveContactGiveEmails = (emailString) => {
    return emailString.split(' ')
  }

  const resolvePublishFlag = (flagString) => {
    return (flagString && (flagString.toLowerCase() === 'yes'))
  }

  const resolvePostcode = async (lat, lng) => {
    const postCode = await api.get(`/geo/postcode?lat=${lat}&lng=${lng}`)
    if (postCode && postCode.data) {
      return postCode.data
    }
    return 'N/A'
  }

  const resolveFilenameFroUrl = (url) => {
    const pathname = new URL(url).pathname
    const index = pathname.lastIndexOf('/')
    return (index !== -1) ? pathname.substring(index + 1) : pathname
  }

  const fetchDocumentfromLink = async (url) => {
    console.log('fetch document from url=', url)

    return axios.get(url, { responseType: 'blob' })
      .then(res => {
        const result = {
          content: new Blob([res.data]),
          name: resolveFilenameFroUrl(url),
          type: res.data.type
        }
        // console.log('result=', result);
        return result
      }).catch(error => {
        console.log(error)
        return null
      })
  }

  const blobToBase64 = async (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  const fetchLinks = async (linkString) => {
    var urls = linkString.split(' ')

    const requests = urls.map(
      (url) => {
        var document = null
        return fetchDocumentfromLink(url)
          .then(async (doc) => {
            document = doc
            return blobToBase64(doc.content)
          })
          .then(async (base64data) => {
            const res = await api.post('/file', {
              filename: document.name,
              data: base64data.split(',')[1]
            })
            const result = {
              name: document.name,
              type: document.type,
              link: res.data.url
            }
            // console.log('result=', result);
            return result
          })
      })

    return Promise.all(requests)
  }

  const resolveAttachments = async (linkString) => {
    //! download from link
    //! upload to storage account
    //! return records
    if (linkString) {
      return fetchLinks(linkString)
        .then(docs => {
          return docs
        })
    }

    return null
  }

  const resolveReadableActivity = (activityString) => {
    if (activityString === 'Charity Support') return 'CHARITY_SUPPORT'
    if (activityString === 'Community Project') return 'COMMUNITY_PROJECT'
    if (activityString === 'Covid-19 Recovery') return 'COVID_RECOVERY'
    if (activityString === 'Professional Services') return 'PROFESSIONAL_SERVICES'
    if (activityString === 'Specialist Training') return 'SPECIALIST_TRAINING'
    if (activityString === 'Education') return 'STEM'
    return null
  }

  const publishOpportunityRecord = async (record) => {
    const contactsGivenEmail = resolveContactGiveEmails(record[6])
    // console.log('contactsGivenEmail', contactsGivenEmail);
    // console.log('onlineUrl=', onlineUrl);

    var physical = await resolvePhysicalAddress(record[10])
    physical.postCode = await resolvePostcode(physical.coords[0], physical.coords[1])

    console.log('physical=', physical)

    const activityType = resolveReadableActivity(record[3])
    const attachments = await resolveAttachments(record[8])
    // console.log('attachments=', attachments);
    const recordPublish = resolvePublishFlag(record[9])

    const x = {
      createdBy: graphProfile.id,
      title: record[0],
      activity: activityType,
      shortDescription: record[4],
      longDescription: record[5],
      startDate: DateTime.fromFormat(record[1],'dd/MM/yyyy HH:mm').toJSDate(),
      endDate:  DateTime.fromFormat(record[2],'dd/MM/yyyy HH:mm').toJSDate(),
      recurrence,
      contacts: contactsGivenEmail,
      activityUrl: activityOnline ? ' ' : null,
      activityLocation: physical,
      noSpaces: record[7] ? record[7] : null,
      supportingDocumentation: attachments
    }
    if (recordPublish) { x.publish = true }
    Object.keys(x).forEach((key) => (x[key] == null || x[key] === '') && delete x[key])
    // const opportunity =
    await api.post('/opportunities', x)
  }

  const postOpportunity = async (publish) => {
    if (publish) setOpportunityPublishing(true)
    if (!publish) setOpportunitySaving(true)

    const a = {
      line1,
      line2,
      town,
      postCode,
      coords: center
    }
    const x = {
      createdBy: graphProfile.id,
      title,
      activity,
      shortDescription,
      longDescription,
      startDate,
      endDate,
      recurrence,
      contacts,
      activityUrl: activityOnline ? ' ' : null,
      activityLocation: (a.line1 && a.line1.length > 0 && a.postCode && a.postCode.length > 0) ? a : null,
      noSpaces,
      supportingDocumentation
    }
    if (publish) x.publish = true
    Object.keys(x).forEach((key) => (x[key] == null || x[key] === '') && delete x[key])
    // console.log(x)
    const opportunity = await api.post('/opportunities', x)

    console.log('opportunity=', opportunity)

    if (opportunity && ((opportunity.status === 200) || (opportunity.status === 201))) {
      if (x.publish) {
        enqueueSnackbar('Opportunity published')
      } else {
        enqueueSnackbar('Opportunity saved as draft')
      }
    }

    setOpportunitySaving(false)
    setOpportunityPublishing(false)
    push('/opportunities')
  }

  const updateOpportunity = async (publish) => {
    const x = {}

    if (publish) {
      x.publish = true
      setOpportunityPublishing(true)
    }

    // console.log("UPDATING OP")
    setOpportunitySaving(true)
    const a = {
      line1,
      line2,
      town,
      postCode,
      coords: center
    }
    // console.log('a', a)
    // console.log('activityLocation', activityLocation)
    // console.log('op.activityLocation', op.activityLocation)

    if (title !== op.title) x.title = title
    if (activity !== op.activity[0]) x.activity = activity
    if (shortDescription !== op.shortDescription) x.shortDescription = shortDescription
    if (longDescription !== op.longDescription) x.longDescription = longDescription
    if (DateTime.fromISO(startDate).ts !== (DateTime.fromISO(op.startDate)).ts) x.startDate = startDate
    if (DateTime.fromISO(endDate).ts !== (DateTime.fromISO(op.endDate)).ts)  x.endDate = endDate
    if (contacts !== op.contacts) x.contacts = contacts
    console.log(activityUrl)
    console.log(op.activityUrl)

    if (activityUrl !== op.activityUrl) x.activityUrl = activityUrl
    if (supportingDocumentation !== op.supportingDocumentation) x.supportingDocumentation = supportingDocumentation


    // if (activityLocation && activityLocation.location) {
    //  console.log(activityLocation.location.coordinates)
    // }
    // console.log(a.coords)

    if (a.coords && (a.coords.length > 0)) {
      if (!activityLocation || !activityLocation.location || activityLocation.location.coordinates) {
        x.activityLocation = a
      } else {
        if (activityLocation && activityLocation.location && (a.coords !== activityLocation.location.coordinates)) {
          x.activityLocation = a
        }
      }
    }

    if (noSpaces !== op.noSpaces) x.noSpaces = noSpaces

    // Object.keys(x).forEach((key) => (x[key] == null || x[key].length <= 0) && delete x[key])
    // console.log(x)
    const o = await api.put(`/opportunities/${id}`, x)
    setOpportunitySaving(false)
    setOpportunityPublishing(false)
    // console.log(o)
    enqueueSnackbar('Opportunity updated')
    push(`/opportunities/${o.data._id}`)
  }

  const getInitialLongDescription = async () => {
    if (update && id) {
      const { data } = await api.get(`/opportunities/${id}`)
      if (data && data.longDescription) {
        // console.log('longDescription', data.longDescription);
        return data.longDescription
      }
      return ''
    }
  }

  const validate = () => {
    // Validate Title
    if (title.length === 0) {
      setValidationError('Title is missing')
      return false
    }
    // Validate Start Date
    if (!startDate || !startDateValid) {
      setValidationError('Please enter a valid start date')
      return false
    }

    // Validate End Date
    if (!endDate || !endDateValid) {
      setValidationError('Please enter a valid end date')
      return false
    }


    // Validate Activity
    if (!activity || activity.length === 0) {
      setValidationError('Please select at least one Activity')
      return false
    }
    // Validate Short Description
    if (shortDescription.length === 0) {
      setValidationError('Short Descrption is missing')
      return false
    }
    // Validate Long Description - skip
    // Validate Contact
    if (contacts.length === 0) {
      // console.log('contacts', contacts);
      setValidationError('Please add at least one Contact')
      return false
    }
    // Validate Activity Location
    // console.log('activityLocation',activityLocation)

    if (activityOnline || activityPhysical) {
      if (activityPhysical) {
        if (line1 && (line1.length > 0) && postCode && (postCode.length > 0)) {
          if (!addressConfirmed) {
            setValidationError('Please confirm Physical Location')
            return false
          }
        } else {
          setValidationError('Please specify Physical Location (min. Line 1 and Post Code)')
          return false
        }
      }

      if (activityOnline) {
        // if (0 === activityUrl.length) {
        //   setValidationError('Please specify Activity URL');
        //   return false;
        // }
      }
    } else {
      setValidationError('Please specify at least one Activity Location')
      return false
    }

    // Validate Support Document - skip
    // Validate Number of Spaces - skip
    return true
  }

  return (
    <>
      {/* <Header isMobile={isMobile}/> */}
      <div style={{
        display: 'flex',
        marginLeft: '32px',
        marginTop: '52px',
        marginRight: '32px',
        alignItems: 'center'
      }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>

          <h1 style={{ color: colors.costainBlue, marginBottom: '4px' }}>
            {!update ? 'New Opportunity' : 'Edit Opportunity'}
          </h1>

          {
            (bulkValidationError.length > 0) &&
              <div
                style={{
                  marginTop: '20px',
                  padding: '20px',
                  backgroundColor: '#f44336',
                  color: 'white',
                  maxWidth: '800px',
                  width: '100%'
                }}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} style={{marginRight:'5px'}}/>{bulkValidationError}

              </div>
          }

          {
            !update && (
              <>
                <input
                  ref={bulkInput}
                  hidden
                  id='csvdocs'
                  onChange={bulkUpload}
                  accept='.csv'
                  type='file'
                />
                {/* eslint-disable-next-line */}
                <p style={{marginTop:'5px',marginBottom:'0px', maxWidth:'800px'}}>Create an opportunity to allow other Costain staff to get involved through the form below. You can also <a href="#"><label style={{cursor:'pointer'}} htmlFor="csvdocs">bulk upload opportunities</label></a> using a csv, download the required template <a href={opTemplate} download='bulk-upload-template.xlsx'>here</a>.  </p>
              </>
            )

          }

        </div>

      </div>
      <div style={{ padding: '32px', display: 'flex', flexDirection: 'column' }}>
        <Label color='rgba(0, 0, 0, .8)' text='Title'/>
        <Input endAdorn={<p style={{ color: 'rgba(0,0,0,0.6)' }}>{90 - title.length}</p>} style={{ alignSelf: 'left' }} inputStyle={{ maxWidth: '800px', width: '100%' }} value={title} onChange={(e) => { if (e.target.value.length <= 90) setTitle(e.target.value) }} />
        <Label style={{}} color='rgba(0, 0, 0, .8)' text='Dates and Times' />
        <DateRangePicker isMobile={isMobile} startDate={startDate} setStartDateValid={setStartDateValid} endDate={endDate} setEndDateValid={setEndDateValid} variant='datetime' setStartDate={setStartDate} setEndDate={setEndDate} />
        <Label style={{}} color='rgba(0, 0, 0, .8)' text='Activity' explainer="Which of the following categories best describes the opportunity?" />
        <MatSelect
          placeholder='Activity Type'
          disableUnderline
          style={{
            height: '36px',
            width: '340px',
            display: 'block',
            outline: 'none',
            border: '1px solid #F1F1F1',
            boxSizing: 'border-box',
            borderRadius: '5px',
            paddingRight: '20px',
            paddingLeft: '8px',
            fontSize: '18px',
            backgroundColor: '#fff',
            lineHeight: '22px',
            color: 'rgba(0, 0, 0, 0.8)',
            '::placeholder': {
              color: 'rgba(0, 0, 0, .5)'
            },
            ':focus': {
              border: '1px solid #DCEAF5'
            },
            '::after': {
              border: '5px solid black'
            },
            boxShadow: 'rgb(232, 232, 233) 15px 4px 150px'
          }}
          defaultValue=""
          value={activity}
          onChange={(e) => setActivity(e.target.value)}
          multiple={false}
          renderValue={(selected) => {
            // console.log(selected)
            if (selected && selected.length >= 1) {
              return `${getActivityIcon(selected)} ${getReadableActivity(selected)}`
            } else {
              return 'Choose an activity type'
            }

          }}
          styles={{ width: '150px' }}
          displayEmpty
        >
          {s.map((ac) => (
            <MenuItem key={ac} value={ac}>
              <Checkbox style={{ color: colors.costainBlue }} checked={activity && activity.indexOf(ac) > -1} />
              <ListItemText style={{ fontFamily: 'Avenir' }} primary={`${getActivityIcon(ac)} ${getReadableActivity(ac)}`} />
            </MenuItem>
          ))}
        </MatSelect>
        <Label style={{}} color='rgba(0, 0, 0, .8)' text='Short Description' explainer="In a tweet (380 characters) describe the opportunity." />
        <TextArea
          rows='4'
          endAdorn={<p style={{ color: 'rgba(0,0,0,0.6)' }}>{380 - shortDescription.length}</p>}
          style={{ alignSelf: 'left' }}
          inputStyle={{ maxWidth: '800px', width: '100%' }}
          value={shortDescription}
          onChange={(e) => { if (e.target.value.length <= 380) setShortDescription(e.target.value) }}
        />

        <Label style={{}} color='rgba(0, 0, 0, .8)' text='Long Description' explainer="Use this to describe the opportunity in more detail."/>
        <RichInput
          setHtmlText={setLongDescription}
          getHtmlText={getInitialLongDescription}
        />

        <Label style={{}} color='rgba(0, 0, 0, .8)' text='Contacts' explainer="Tag points of contact for this opportunity here, just start typing their name." />
        <ChipInput
          classes={{
            ...classes
          }}
          onBlur={(e) => {
            if (!selectingContact) {
              setContactSelect(false)
            }
          }}
          disabled={(user && user.type === 'external')}
          fullWidth
          onUpdateInput={(e) => onContactChange(e)}
          chipRenderer={({ chip }) => {
            return (
              <Chip
                key={chip.id || chip._id}
                style={{ marginLeft: '3px', marginRight: '3px', backgroundColor: colors.costainBlue, alignSelf:'center' }}
                avatar={chip.pic && (<Avatar src={chip.pic}>M</Avatar>)}
                label={chip.name || chip.displayName || chip}
                color='primary'
                onDelete={() => {
                  setContactChips(ChipUtils.deleteChipItem(chip, contactChips))
                  setContacts(ChipUtils.deleteItem(chip, contacts))
                }}
                deleteIcon={<FontAwesomeIcon icon={faTimesCircle} />}
              />
            )
          }}
          value={contactChips}
          placeholder='Enter points of contacts for this opportunity'
          disableUnderline
          name='owners'
          dataSource={contactChips}
          dataSourceConfig={{ name: 'name', pic: 'pic', text: 'name', value: 'name' }}
          onBeforeAdd={handleAddContact}
        />
        {contactSelect && (
          <div
            onMouseDown={(e) => setSelectingContact(true)} style={{
              width: '100%',
              maxWidth: '800px',
              backgroundColor: 'white',
              height: '200px',
              boxShadow: 'rgb(232, 232, 233) 15px 4px 150px',
            borderRadius: '0px 0px 10px 10px',
            marginTop:'10px',
            overflow: 'scroll'
            }}
          >
            {searchContacts.map(contact => (
              contact.mail && (
                <div
                  key={contact.id}
                  onClick={() => handleAddContact(contact.id)} style={{
                    width: '100%',
                    borderBottom: '1px solid rgb(232, 232, 233)',
                    height: '36px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                >
                  <p
                    style={{ marginLeft: '8px' }}
                  >{contact.displayName}{contact.mail && (` (${contact.mail.toLowerCase()})`)}
                  </p>
                </div>
              )
            ))}

          </div>
        )}
        <Label style={{}} color='rgba(0, 0, 0, .8)' text='Activity Location' />
        <div style={{display:'flex', alignItems:'center'}}>
          <Checkbox
            checked={activityOnline} value={activityOnline} onChange={(e) => {
              setActivityOnline(!activityOnline)
              if (activityOnline) {
                setActivityUrl(null)
              } else {
                setActivityUrl(" ")
              }
            }}
          />
          <span
            style={{ cursor: 'pointer' }} onClick={() => {
              setActivityOnline(!activityOnline)
              if (activityOnline) {
                setActivityUrl(null)
              } else {
                setActivityUrl(" ")
              }
          }}
          >Online (Teams Meeting)
          </span>
          <Checkbox
            checked={activityPhysical} value={activityPhysical} onChange={(e) => {
              setActivityPhysical(!activityPhysical)
            }}
          />
          <span
            style={{ cursor: 'pointer' }} onClick={() => {
              setActivityPhysical(!activityPhysical)
            }}
          >Physical Address
          </span>
        </div>
        <>

          {activityPhysical && (
            <>
            <div style={{borderLeft:!confirmingAddress ? '3px solid grey': null, paddingLeft:'16px', marginTop:'30px'}}>
              {confirmingAddress && (
                <div style={{ height: '400px' }}>
                  <ConfirmAddress setAddressConfirmed={setAddressConfirmed} setConfirmingAddress={setConfirmingAddress} setCenter={setCenter} relative mapWidth='600px' center={center} />

                </div>
              )}
              {!confirmingAddress && (
                <>
                  <Label style={{marginTop:0}} color='rgba(0, 0, 0, .8)' text='Address Line 1' />
                  <Input
                    value={line1} onChange={(e) => {
                      setLine1(e.target.value)
                      setAddressConfirmed(false)
                    }} style={{ maxWidth: '800px', width: '100%', alignSelf: 'left' }}
                  />
                  <Label style={{}} color='rgba(0, 0, 0, .8)' text='Address Line 2' />
                  <Input
                    value={line2} onChange={(e) => {
                      setLine2(e.target.value)
                      setAddressConfirmed(false)
                    }} style={{ maxWidth: '800px', width: '100%', alignSelf: 'left' }}
                  />
                  <Label style={{}} color='rgba(0, 0, 0, .8)' text='Town' />
                  <Input
                    value={town} onChange={(e) => {
                      setAddressConfirmed(false)
                      setTown(e.target.value)
                    }} style={{ maxWidth: '800px', width: '100%', alignSelf: 'left' }}
                  />
                  <Label style={{}} color='rgba(0, 0, 0, .8)' text='Post Code' />
                  <Input
                    value={postCode} inputStyle={{ width: '110px' }} onChange={(e) => {
                      setAddressConfirmed(false)
                      setPostCode(e.target.value)
                    }} style={{ maxWidth: '800px', width: '60px', alignSelf: 'left' }}
                  />
                </>
              )}

            </div>
              {!confirmingAddress && (
                <Button disabled={addressConfirmed} icon={addressConfirmed ? <FontAwesomeIcon style={{ marginRight: '5px' }} icon={faCheckCircle} /> : null} onClick={() => confirmAddress()} style={{ marginLeft: 0, marginTop: '15px' }} loading={preparing} title='Confirm Address' />

              )}

            </>
          )}
        </>

        <Label style={{}} color='rgba(0, 0, 0, .8)' text='Supporting Documents' explainer="Document links will be attached to email confirmation / calendar invite. " />
        {
          supportingDocumentation.length > 0 && supportingDocumentation.map(x => (
            <div style={{ padding: '5px', margin: '5px 5px 5px 0px', flexDirection:'row', display:'flex', alignItems:'center' }}>
              <FontAwesomeIcon
                color='#930041'
                onClick={() => setSupportingDocumentation(supportingDocumentation.filter(y => y.link !== x.link))}
                style={{ marginRight: '5px', cursor: 'pointer' }}
                icon={faMinusCircle}
              />
              <p>{x.name}</p>
            </div>
          ))
        }

        <Button
          onClick={() => filesInput.current.click()}
          loading={uploadingDocs}
          icon={<FontAwesomeIcon icon={faUpload} style={{ marginRight: '8px' }} />}
          title='Upload Files'
          style={{ marginLeft: '5px', marginTop: '10px' }}
        />

        <input
          hidden
          ref={filesInput}
          id='docs'
          onChange={uploadSupportingDocs}
          multiple
          type='file'
        />

        <Label style={{}} color='rgba(0, 0, 0, .8)' text='Number of spaces available' explainer="This restricts the number of spaces available to book on an opportunity."/>
        <Input type="number" pattern="[0-9]*" endAdorn={<p style={{ marginLeft: '-30px', color: 'rgba(0,0,0,0.6)' }}>people</p>} style={{ alignSelf: 'left' }} inputStyle={{ width: '140px' }} value={noSpaces} onChange={(e) => {
          setNoSpaces((v) => e.target.validity.valid ? e.target.value: v)
        }} placeholder='∞' />

        {
          (validationError.length > 0) &&
            <div
              style={{
                marginTop: '20px',
                padding: '20px',
                backgroundColor: '#f44336',
                color: 'white',
                maxWidth: '800px',
                width: '100%'
              }}
            >
              <FontAwesomeIcon icon={faExclamationTriangle} style={{marginRight:'5px'}}/>{validationError}
            </div>
        }

        <div style={{ display: 'flex' }}>
          {status === 'DRAFT' && (
            <Button
              icon={<FontAwesomeIcon style={{ marginRight: '6px' }} icon={faSave} />}
              loading={opportunitySaving}
              variant='secondary'
              onClick={() => validate() && (update ? updateOpportunity(false) : postOpportunity(false))}
              style={{ marginLeft: '0', marginRight: '8px', marginTop: '35px' }}
              title='Save Draft'
            />
          )}
          <Button
            icon={<FontAwesomeIcon
              style={{ marginRight: '6px' }}
              icon={faPaperPlane}
                  />}
            loading={opportunityPublishing}
            variant='primary'
            onClick={() => validate() && (update ? updateOpportunity(true) : postOpportunity(true))}
            style={{ marginLeft: '0', marginTop: '35px' }}
            title='Save & Publish'
          />
        </div>

      </div>

    </>

  )
}

export default NewOpportunity
