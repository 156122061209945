import React, { useEffect, useState } from 'react'
// import MoonLoader from 'react-spinners/MoonLoader'
import { useHistory, useLocation } from 'react-router-dom'
import Input from '../../components/Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faFilter, faList, faTimes } from '@fortawesome/free-solid-svg-icons'
import faSearch from '../../assets/search-solid.svg'
import MoonLoader from 'react-spinners/MoonLoader'
import { Button } from '@costain/react-ui'
import faPlusCircle from '../../assets/plus-circle-solid.svg'
import DateRangePicker from '../../components/DateRangePicker'
import SmallChip from '../../components/SmallChip'
import getReadableActivity from '../../utils/getReadableActivity'
import { Checkbox, Tooltip } from '@material-ui/core'
import airSupport from '../../assets/undraw_air_support_wy1q.svg'
import { css } from 'glamor'
import OpportunityCard from './OpportunityCard'
import colors from '../../components/_colors'
import getActivityIcon from '../../utils/getActivityIcon'
import { DateTime } from 'luxon'

function OpportunitiesSearch ({ setSelectedOpportunity, myOpsOpen, user, setMyOpsOpen, myOps, open, setOpen, search, opportunities, setSearch, loading, searchError, hovering, setHovering, queryActivities, setQueryActivities, queryOnline, setQueryOnline, queryPhyiscal, setQueryPhyiscal, queryStart, setQueryStart, queryEnd, setQueryEnd, isMobile, queryUpcoming, setQueryUpcoming, queryComplete, setQueryComplete }) {
  const [localSearch, setLocalSearch] = useState('')
  const [filters, setFilters] = useState(false)
  const [startDateValid, setStartDateValid] = useState(false)
  const [endDateValid, setEndDateValid] = useState(false)

  // const [ownOpportunities, setOwnOpportunities] = useState([])
  // const [bookedOpportunities, setBookedOpportunities] = useState([])

  const { replace } = useHistory()
  const location = useLocation()

  useEffect(() => {
    setSelectedOpportunity(null)
    console.log("location change")
    const params = new URLSearchParams(location.search)
    const s = params.get('search')
    const a = params.get('activities')
    const o = params.get('online')
    const p = params.get('physical')
    const st = params.get('startDate')
    const en = params.get('endDate')
    //const up = params.get('upcoming')
    const co = params.get('complete')

    setLocalSearch(s)

    if (search !== s) {
      if (s && s.length > 0) {
        setSearch(s)
        setFilters(true)
      }
    }

    if (a !== queryActivities) {
      if (a && a.length > 0) {
        console.log(a)
        setQueryActivities(a)
        setFilters(true)
      }
    }

    if (o !== queryOnline) {
      if (o && o.length > 0) {
        console.log(o)
        setQueryOnline(false)
        setFilters(true)
      }
    }

    if (p !== queryPhyiscal) {
      if (p && p.length > 0) {
        console.log(p)
        setQueryPhyiscal(false)
        setFilters(true)
      }
    }

    // if (up !== queryUpcoming) {
    //   if (up && up.length > 0) {
    //     console.log(up)
    //     setQueryUpcoming(true)
    //   }
    // }
    if (co !== queryComplete) {
      if (co && co.length > 0) {
        console.log(co)
        setQueryComplete(true)
      }
    }
    // console.log(DateTime.fromISO(st).startOf('day'))
    // console.log((queryStart).startOf('day'))


    if (st && startDateValid) {
      if (queryStart &&  (DateTime.fromISO(st).startOf('day').toMillis() === (queryStart).startOf('day').toMillis())) {
        return
      }
      if (st.length > 0) {
        console.log(st)
        setQueryStart(DateTime.fromISO(st).startOf('day'))
      }
      if ((DateTime.fromISO(st).startOf('day').toMillis() !== (DateTime.now().startOf('day')).startOf('day').toMillis())){
        setFilters(true)
      }
    }

    if (en && endDateValid) {
      if (queryEnd &&  (DateTime.fromISO(st).startOf('day').toMillis() === (queryEnd).startOf('day').toMillis())) {
        return
      }
      if (en.length > 0) {
        console.log(en)
        setQueryEnd(DateTime.fromJSDate(new Date(en)).startOf('day'))
        setFilters(true)
      }
    }
    // eslint-disable-next-line
  },[location])

  // useEffect(() => {
  //   console.log(opportunities)
  //   console.log(search)
  //   console.log(loading)
  //   console.log(searchError)
  //
  //   // if (opportunities && search.length > 0 && !loading && !searchError) {
  //   //   console.log("TRUE")
  //   //   setOpen(true)
  //   // } else {
  //   //   console.log("FALSE")
  //   //   setOpen (false)
  //   // }
  // }, [search, searchError, opportunities, loading])

  const handleSearch = (x) => {
    setMyOpsOpen(false)
    setOpen(true)
    setLocalSearch(x)
    setSearch(x)
  }

  const activityIsSelected = (x) => {
    let y = []
    if (queryActivities.length > 0) {
      if (queryActivities.includes(',')) {
        y = queryActivities.split(',')
      } else {
        y = [queryActivities]
      }
    }
    return { activities: y, activityPresent: !!y.includes(x) }
  }

  const handleAddActivityFilter = (x) => {
    console.log(x)
    let newFilters = []
    const { activities, activityPresent } = activityIsSelected(x)
    console.log(activities)
    if (activityPresent) {
      newFilters = activities.filter(activity => activity !== x)
    } else {
      newFilters = activities
      newFilters.push(x)
    }
    let newFilterString = newFilters
    console.log(newFilters)
    if (newFilters.length > 1) {
      newFilterString = newFilters.join(',')
    } else {
      newFilterString = newFilters[0]
    }
    console.log(newFilterString)
    setQueryActivities(newFilterString || '')
  }

  return (

    <>
      {!isMobile && (
        <h3 style={{ marginTop: 0, marginBottom: '4px' }}>Volunteer Hub <span role='img' aria-label='Raised Hand'>✋</span></h3>
      )}
      <Input
        noHighlight
        value={localSearch}
        onChange={(e) => setLocalSearch(e.target.value)}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            console.log(e.target.value)
            e.target.blur()
            handleSearch(e.target.value)
          }
        }}
        inputStyle={{ width: '100%' }}
        style={{ width: '100%' }}
        placeholder='Find ways to give back'
        endAdorn={
          <div>
            {(search && search.length > 0 && (localSearch === search) && !loading) && (
              <FontAwesomeIcon
                style={{ cursor: 'pointer' }} icon={faTimes} onClick={() => {
                  const queryParams = new URLSearchParams(location.search)
                  queryParams.delete('search')
                  replace({
                    search: queryParams.toString()
                  })
                  setSearch('')
                  setLocalSearch('')
                }}
              />
            )}
            {!loading && (
              <>
                {((!loading && (!search || search.length <= 0)) || search !== localSearch) && (
                  <img
                    onClick={() => {
                      handleSearch(localSearch)
                    }}
                    style={{
                      width: '16px',
                      cursor: 'pointer'
                    }} src={faSearch} alt='Search'
                  />
                )}
              </>
            )}

            {loading && (
              <MoonLoader size={16} />
            )}

          </div>
        }
      />

      <p style={{ fontSize: '10px', marginTop: 0, marginBottom: 0, marginLeft: '7px' }}>Enter your postcode,  activity name or type</p>
      <div style={{ display: 'flex' }}>
        {/* <OpportunityMarker setHovering={setHovering} hovering={hovering}/> */}
      </div>
      <div style={{ display: 'flex', paddingBottom: '5px' }}>
        <Button tooltip="My Ops" active={myOpsOpen} onClick={() => {
          setMyOpsOpen(!myOpsOpen)
          if(!open) setOpen(true)
          if(filters) setFilters(false)
        }} style={{ minWidth: '60px', padding: '0px', marginLeft: '4px', marginRight: '8px', border: '1px solid #E8E8E9', boxShadow: '0px 2px 0px #E8E8E9' }} title='' icon={<FontAwesomeIcon color='rgba(101, 97, 125, 0.8)' icon={faCalendar} />} />
        {!isMobile && (
          <Button tooltip={open ? "Hide list" : "Show list"} active={open} onClick={() => setOpen(!open)} style={{ minWidth: '60px', padding: '0px', maxWidth: '60px', width: '60px', marginLeft: 0, marginRight: '8px' }} title='' icon={<FontAwesomeIcon color='rgba(101, 97, 125, 0.8)' icon={faList} />} />
        )}
        <Button tooltip="Filters" active={filters} disabled={myOpsOpen} onClick={() => setFilters(!filters)} style={{ minWidth: '60px', marginRight: '8px', padding: '0px', marginLeft: '0px', border: '1px solid #E8E8E9', boxShadow: '0px 2px 0px #E8E8E9' }} title='' icon={<FontAwesomeIcon color='rgba(101, 97, 125, 0.8)' icon={faFilter} />} />
        <Button to='/opportunities/new' textStyle={{ fontSize: '14px' }} style={{ minWidth: '62px', width: '100%', marginLeft: 0, marginRight: '4px' }} title='Opportunity' icon={<img alt='Add' style={{ width: '16px', marginRight: '3px', filter: 'invert(25%) sepia(80%) saturate(347%) hue-rotate(86deg) brightness(91%) contrast(92%)' }} src={faPlusCircle} />} />
      </div>
      {filters && (
        <div style={{ display: 'flex', marginBottom: '20px', flexDirection: 'column' }}>
          <div style={{ display: 'flex', marginBottom: '8px' }}>
            <DateRangePicker setEndDateValid={setEndDateValid} setStartDateValid={setStartDateValid} setDateImmediately placeholders={["Starts After", "Ends Before"]} isMobile={isMobile} style={{ marginBottom: '10px' }} startDate={queryStart} setStartDate={setQueryStart} endDate={queryEnd} setEndDate={setQueryEnd} />
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '8px' }}>
            {
              process.env.REACT_APP_ACTIVITY_TYPES.split(',').map((type) => (
                <SmallChip key={type} onClick={() => handleAddActivityFilter(type)} value={`${getActivityIcon(type)} ${getReadableActivity(type)}`} style={{ whiteSpace: 'nowrap', cursor: 'pointer', color: activityIsSelected(type).activityPresent ? 'white' : 'black', backgroundColor: activityIsSelected(type).activityPresent ? colors.costainBlue : '#efefef' }} />
              ))
            }
          </div>

          <div style={{ display: 'flex' }}>
            <span>
              <Checkbox checked={queryOnline} onChange={(e) => setQueryOnline(e.target.checked)} />
            <span style={{cursor:'pointer'}} onClick={() => setQueryOnline(!queryOnline)}>Online</span>
            </span>
            <span>
              <Checkbox checked={queryPhyiscal} onChange={(e) => setQueryPhyiscal(e.target.checked)} />
            <span style={{cursor:'pointer'}} onClick={() => setQueryPhyiscal(!queryPhyiscal)}>Physical</span>
            </span>

          </div>

          {/*<div style={{ display: 'flex' }}>*/}
          {/*  <span>*/}
          {/*    <Checkbox checked={queryUpcoming} onChange={(e) => setQueryUpcoming(e.target.checked)} />*/}
          {/*  Upcoming*/}
          {/*  </span>*/}
          {/*  <span>*/}
          {/*    <Checkbox checked={queryComplete} onChange={(e) => setQueryComplete(e.target.checked)} />*/}
          {/*  Complete*/}
          {/*  </span>*/}

          {/*</div>*/}

        </div>
      )}
      <div {...css({
        maxHeight: isMobile ? '100%' : '200px',
        height: (open || isMobile) ? isMobile ? '100%' : '300px' : '0px',
        transition: 'height 0.2s ease-out',
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        overflow: 'scroll',
        '::-webkit-scrollbar': {
          display: 'none'
        }
      })}
      >

        {(!loading && ((!myOpsOpen && opportunities.length <= 0) || (myOpsOpen && myOps.length <= 0))) && (
          <>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto', alignItems: 'center', marginTop: '8px' }}>
              <img alt='Rocket' style={{ width: '164px', marginTop: '5px' }} src={airSupport} />
              <h2 style={{ fontWeight: 500, fontSize: '18px', color: colors.costainDarkBlue }}>{!search ? 'No opportunities yet!' : 'No results found'} </h2>
            </div>
          </>
        )}
        {!myOpsOpen && opportunities && opportunities.map((op, index) => (
          <OpportunityCard isMobile={isMobile} interactive user={user} op={op} key={index} hovering={hovering} setHovering={setHovering} />
        ))}

        {myOpsOpen && (
          <>
            {myOpsOpen && myOps && myOps.map(op => (
              <OpportunityCard isMobile={isMobile} interactive user={user} own op={op} hovering={hovering} setHovering={setHovering} />
            ))}
          </>
        )}
      </div>

    </>

  )
}

export default OpportunitiesSearch
