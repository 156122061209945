import React from 'react'
import NewActivity from '../Activities/NewActivity'
import Activities from '../Activities/Activities'
import { css } from 'glamor'

// eslint-disable-next-line react/prop-types
const FeedPanel =
({
  user,
  graphProfile,
  api,
  graph,
  opportunities,
  hovering,
  setHovering,
  searchText,
  setSearchText,
  loading,
  setLoading,
  setCenter,
  setZoom,
  activities,
  setActivities,
  selectedOpportunity,
  setSelectedOpportunity,
  getActivities,
  fetchMoreData,
  hasMoreData,
  backToFirstPage,
  isMobile,
  setPage,
  page
}) => {
  const style = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    height: '100%',
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none'
    }
  })

  return (
    <>
      <div {...style}>

        <div style={{ alignItems: 'center', borderBottom: '1px solid #e8e8e8' }}>
          <h1 style={{ marginLeft: '5px', fontSize: '20px', padding: '10px', marginTop: 0, marginBottom: 0 }}>Share</h1>
        </div>
        <NewActivity
          getActivities={getActivities}
          graphProfile={graphProfile}
          graph={graph}
          api={api}
        />

        <Activities
          isMobile={isMobile}
          setSelectedOpportunity={setSelectedOpportunity}
          selectedOpportunity={selectedOpportunity}
          user={user}
          setActivities={setActivities}
          activities={activities}
          setZoom={setZoom}
          setCenter={setCenter}
          hovering={hovering}
          setHovering={setHovering}
          searchText={searchText}
          setSearchText={setSearchText}
          loading={loading}
          setLoading={setLoading}
          opportunities={opportunities}
          graph={graph}
          graphProfile={graphProfile}
          api={api}
          fetchMoreData={fetchMoreData}
          backToFirstPage={backToFirstPage}
          hasMoreData={hasMoreData}
          page={page}
          setPage={setPage}
        />
      </div>
    </>
  )
}

export default FeedPanel
