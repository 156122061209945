import colors from '../components/_colors'

const getStatusColor = (activity, owned) => {
  if (activity.completed) return colors.brightGreen
  if (!owned && activity.status === 'LIVE' && !activity.completed) return 'black'
  if (activity.status === 'DRAFT') return colors.orange
  if (activity.status === 'LIVE') return colors.darkRed
}

export default getStatusColor
