import React, { useState, useRef } from 'react'
import { Button } from '@costain/react-ui'
import Label from './Label'
import './mention-style.css'
import DropdownButton from './DropdownButton'
import Input from './Input'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Gallery from './Gallery'
import TaggableTextArea from './TaggableTextArea'

function ActivityCreationForm ({
  searchUsers,
  update,
  updateActivity,
  postActivity,
  activityReqPending,
  labelTitle,
  activityId,
  opportunityOptions,
  setSelected,
  opportunity,
  graph
}) {
  const [description, setDescription] = useState('')
  const [mentions, setMentions] = useState([])
  const [imageFiles, setImageFiles] = useState([])
  const [h, setH] = useState(null)

  const hiddenFileInput = useRef(null)

  const handleUpload = event => {
    hiddenFileInput.current.click()
  }

  const handleDescription = (e, newValue, newPlainTextValue, tags) => {
    setDescription(newValue)
    setMentions(tags)
  }

  const addImage = (e) => {
    const iFiles = e.target.files
    const imgArray = [...imageFiles]

    for (let i = 0; i < iFiles.length; i++) {
      if (i > 3) return
      const reader = new FileReader()
      reader.addEventListener('loadend', (e) => {
        imgArray.push(e.target.result)
        setImageFiles([...imgArray])
      })
      reader.readAsDataURL(iFiles[i])
    }
  }

  const removeImage = index => {
    var array = [...imageFiles]
    if (index !== -1) {
      array.splice(index, 1)
      setImageFiles(array)
    }
  }

  const clearDescription = () => {
    setDescription('')
    setImageFiles([])
  }

  return (
    <>
      {
        (labelTitle) &&
          <Label style={{}} color='rgba(0, 0, 0, .8)' text={{ labelTitle }} />
      }

      <div style={{ marginTop: '10px' }}>
        <TaggableTextArea value={description} placeholder='What did you get up to?' handleChange={handleDescription} graph={graph} />

      </div>
      <div style={{ display: 'flex' }}>
        <DropdownButton
          placeholder='Tag an opportunity'
          options={
            opportunityOptions
          }
          setSelected={setSelected}
        />
      </div>

      {
        (imageFiles && imageFiles.length > 0) && (
          <Gallery style={{ marginTop: '1em' }} images={imageFiles} removeImage={removeImage} />
        )

      }
      <span style={{ display: 'inline-flex', width: '100%',marginTop:'5px' }}>
        <Button
          variant='secondary'
          onClick={handleUpload}
          style={{ zIndex: 'auto' }}
          labelStyle={{ marginRight: '10px' }}
          title=''
          icon={<FontAwesomeIcon icon={faImage} />}
        />

        <input
          multiple
          style={{ display: 'none' }}
          type='file'
          accept='image/*'
          ref={hiddenFileInput}
          onChange={addImage}
        />

        <Button
          disabled={description.length <=0 && imageFiles.length <= 0 }
          loading={activityReqPending}
          variant='primary'
          style={{ zIndex: 'auto' }}
          textStyle={{ fontSize: 'medium' }}
          labelStyle={{ marginRight: '0px', marginLeft: '0px' }}
          onClick={() => {
            if (update) {
              updateActivity(activityId, description, mentions, imageFiles, clearDescription)
            } else {
              postActivity({ description, mentions, imageFiles, hours: h })
              setDescription('')
              setMentions(null)
              setImageFiles([])
              setH(null)
            }
          }}
          title={!update ? 'Post' : 'Update'}
        />
      </span>

    </>

  )
}

export default ActivityCreationForm
