import React, { useState } from 'react'
import {
  Switch,
  Route
} from 'react-router-dom'
import OpportunitiesSearch from './OpportunitiesSearch'
import Opportunity from './Opportunity'
import { css } from 'glamor'

// eslint-disable-next-line react/prop-types
const OpportunityPanel = ({ user, setSelectedOpportunity, mobMap, setMobMap, myOpsOpen, setMyOpsOpen, myOps, setZoom, setCenter, search, opportunities, setSearch, loading, searchError, hovering, setHovering, queryActivities, setQueryActivities, queryOnline, setQueryOnline, queryPhyiscal, setQueryPhyiscal, queryStart, setQueryStart, queryEnd, setQueryEnd, isMobile, queryUpcoming, queryComplete, setQueryUpcoming, setQueryComplete }) => {
  const [open, setOpen] = useState(true)

  const style = css({
    display: mobMap ? 'none' : 'block',
    boxShadow: '15px 4px 250px rgb(133 133 133 / 30%)',
    width: isMobile ? '400px' : '450px',
    minHeight: '120px',
    position: 'absolute',
    left: !isMobile ? '253px' : 0,
    right: 0,
    top: 0,
    marginLeft: 'auto',
    zIndex: '999',
    backgroundColor: '#fff',
    borderRadius: '10px 0px 0px 10px',
    marginTop: '10px',
    maxHeight:'100%',
    '@media(max-width: 900px)': {
      width: '100%',
      height: '100%',
      borderRadius: '0px',
      marginTop: '0px'
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    overflow: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none'
    }
  })

  return (

    <div {...style}>
      {/* <Header isMobile={isMobile}/> */}
      <div style={{ padding: isMobile ? '52px 20px' : '20px', paddingRight: !isMobile ? '20px' : '20px' }}>
        <Switch>
          <Route exact path='/opportunities'>
            <OpportunitiesSearch setSelectedOpportunity={setSelectedOpportunity} user={user} queryUpcoming={queryUpcoming} setQueryUpcoming={setQueryUpcoming} queryComplete={queryComplete} setQueryComplete={setQueryComplete} myOps={myOps} myOpsOpen={myOpsOpen} setMyOpsOpen={setMyOpsOpen} isMobile={isMobile} setOpen={setOpen} open={open} loading={loading} search={search} setSearch={setSearch} opportunities={opportunities} searchError={searchError} hovering={hovering} setHovering={setHovering} queryActivities={queryActivities} setQueryActivities={setQueryActivities} queryOnline={queryOnline} setQueryOnline={setQueryOnline} queryPhyiscal={queryPhyiscal} setQueryPhyiscal={setQueryPhyiscal} queryStart={queryStart} setQueryStart={setQueryStart} queryEnd={queryEnd} setQueryEnd={setQueryEnd} />
          </Route>
          <Route exact path='/opportunities/:id'>
            <Opportunity isMobile={isMobile} setSelectedOpportunity={setSelectedOpportunity} setMobMap={setMobMap} user={user} setZoom={setZoom} setCenter={setCenter} setOpen={setOpen} open={open} loading={loading} search={search} setSearch={setSearch} opportunities={opportunities} searchError={searchError} hovering={hovering} setHovering={setHovering} queryActivities={queryActivities} setQueryActivities={setQueryActivities} queryOnline={queryOnline} setQueryOnline={setQueryOnline} queryPhyiscal={queryPhyiscal} setQueryPhyiscal={setQueryPhyiscal} queryStart={queryStart} setQueryStart={setQueryStart} queryEnd={queryEnd} setQueryEnd={setQueryEnd} />
          </Route>
        </Switch>
      </div>

    </div>
  )
}

export default OpportunityPanel
