import React, { useEffect, useState } from 'react'
import { KeyboardDateTimePicker, KeyboardDatePicker } from '@material-ui/pickers'
import { faArrowDown, faArrowRight, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Input from './Input'
import { IconButton } from '@material-ui/core';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { DateTime } from 'luxon'

function DateRangePicker ({
  key, onClick, style, children, setStartDate, setStartDateValid, setEndDate, setEndDateValid, variant, startDate, endDate, isMobile, placeholders, setDateImmediately
}) {
  const [open, setOpen] = useState(false)
  const [endOpen, setEndOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const divRef = React.useRef();

  const [startInput, setStartInput] = useState(null)
  const [endInput, setEndInput] = useState(null)

  useEffect(() => {
    if (startDate) {
      setStartInput(startDate)
      if (startDate.isValid){
        setStartDateValid(true)
      }
    }
    if (endDate) {
      setEndInput(endDate)
      if (endDate.isValid) {
        setEndDateValid(true)
      }
    }

  },[startDate,endDate])


  if (!variant) variant = 'date'

  const defaultStyle = {
    display: 'flex',
    alignItems: 'center'
  }

  const s = { ...defaultStyle, style }

  // This updates the date in the parent as soon as one is available. Can cause UX issues due to rerenders but it is disarable for i.e the buttonless filter on the search
  const updateDates = (setter, setValid, date) => {
      let d;
      if (!date) return
      if (!date.isValid && !(date instanceof DateTime) && !setDateImmediately) {
        d = DateTime.fromFormat( date.target.value,'dd/MM/yy HH:mm')
      } else {
        d = date
      }
      if (d && !d.isValid) {
        setValid(false)
      }
      if (d && d.isValid) {
        setValid(true)
        setter(d)
      }

  }

  return (
    <div
      ref={divRef}
      style={s}
      key={key}
      onClick={onClick}
    >
      {variant === 'datetime' && (
        <div style={{display:'flex', flexDirection: isMobile ? 'column':'row'}}>
          <KeyboardDateTimePicker
            onChange={() => {}}
            autoOk
            variant={isMobile ? 'dialog' : 'inline'}
            open={open}
            PopoverProps={{anchorEl, anchorOrigin: {horizontal: 'left', vertical: 'bottom'}, transformOrigin: {horizontal:'100px'}}}
            onOpen={() =>  {
              setOpen(true)
            }}
            onClose={() => setOpen(false)}
            style={{width:'auto'}}
            value={startInput}
          TextFieldComponent={(props) => <Input
              onBlur={(e) => {
                if (!setDateImmediately) {
                  updateDates(setStartDate, setStartDateValid,e)
                }
              }}
              placeholder={placeholders ? placeholders[0] : "Start Date"} endAddornMargin="-42px" inputStyle={{width:'200px', padding:'5px'}} endAdorn={<IconButton  onClick={() => {
            setAnchorEl(divRef.current);
            setOpen(true)
            } } style={{marginRight:'5px'}}><FontAwesomeIcon size="xs" icon={faCalendarAlt} /> </IconButton>} {...props}/>}
            ampm={false}
            helperText={null}
            error={false}
            format='dd/MM/yy HH:mm'
            onAccept={(date) => {
              setStartDate(date)
              setStartInput(date)
            }}

          />

          <FontAwesomeIcon icon={isMobile ? faArrowDown : faArrowRight} style={{ margin:'15px' }}/>
          <KeyboardDateTimePicker
            autoOk
            onChange={() => {}}
            variant={isMobile ? 'dialog' : 'inline'}
            open={endOpen}
            onOpen={() => setEndOpen(true)}
            onClose={() => setEndOpen(false)}
            style={{width:'auto'}}
            PopoverProps={{anchorEl, anchorOrigin: {horizontal: 'left', vertical: 'bottom'}, transformOrigin: {horizontal:'100px'}}}
            TextFieldComponent={(props) => <Input
              onBlur={(e) => {
                if (!setDateImmediately) {
                  updateDates(setEndDate, setEndDateValid,e)
                }
              }}
              placeholder={placeholders ? placeholders[1] : "End Date"} endAddornMargin="-42px" inputStyle={{width:'200px', padding:'5px'}} endAdorn={<IconButton onClick={() => {
              setAnchorEl(divRef.current);
              setEndOpen(true)
            }} style={{marginRight:'5px'}}><FontAwesomeIcon size="xs" icon={faCalendarAlt} /> </IconButton>} {...props}/>}
            ampm={false} helperText={null} error={false} value={endInput}
            InputProps={{ disableUnderline: true, placeholder: 'End Date'}} format='dd/MM/yy HH:mm'
            onAccept={(date) => {
              setEndDate(date)
              setEndInput(date)
            }}
          />
        </div>
      )}
      {variant === 'date' && (
        <div style={{display:'flex', flexDirection: isMobile ? 'column':'row', alignItems:'center'}}>
        <KeyboardDatePicker
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            PopoverProps={{anchorEl}}
            style={{width:'auto'}}
            onAccept={(date) => {
              setStartDate(date)
              setStartInput(date)
            }}
            TextFieldComponent={(props) => <Input
              retainAdornWhenDisabled
              onBlur={(e) => {
                if (!setDateImmediately) {
                  updateDates(setStartDate, setStartDateValid,e)
                }
              }}
              placeholder={placeholders ? placeholders[0] : "Start Date"} endAddornMargin="-42px" inputStyle={{width:'185px', padding:'5px'}} endAdorn={<IconButton  onClick={(e) => {
              setAnchorEl(divRef.current);
              setOpen(true)
            }} style={{marginRight:'5px'}}><FontAwesomeIcon size="xs" icon={faCalendarAlt} /> </IconButton>} {...props}
            disabled
            />}
            helperText={null} error={false} value={startInput} variant='inline'
                              format='dd/MM/yyyy'
                              autoOk
                              onChange={(stDate) => {
                                if (setDateImmediately) {
                                  updateDates(setStartDate, setStartDateValid, stDate)
                                }
                              }}/>
          <FontAwesomeIcon icon={isMobile ? faArrowDown : faArrowRight} style={{ margin: '8px' }}/>
          <KeyboardDatePicker
            open={endOpen}
            PopoverProps={{anchorEl}}
            onOpen={() => setEndOpen(true)}
            onClose={() => setEndOpen(false)}
            style={{width:'auto'}}
            TextFieldComponent={(props) => <Input
              retainAdornWhenDisabled
              onBlur={(e) => {
                if (!setDateImmediately) {
                  updateDates(setEndDate, setEndDateValid,e)
                }
                }
              }

              placeholder={placeholders ? placeholders[1] : "End Date"} endAddornMargin="-42px" inputStyle={{width:'185px', padding:'5px'}} endAdorn={<IconButton onClick={() => {
              setAnchorEl(divRef.current);
              setEndOpen(true)
            }} style={{marginRight:'5px'}}><FontAwesomeIcon size="xs" icon={faCalendarAlt} /> </IconButton>} {...props}
            disabled
            />}
            helperText={null} error={false} value={endInput} variant='inline'
                              InputProps={{ disableUnderline: true, placeholder: placeholders ? placeholders[1] : "End Date"} } format='dd/MM/yyyy'
                              autoOk
                              minDate={startDate ? startDate : new Date('1900-01-01')}
                              onChange={(enDate) => {
                                {
                                  if (setDateImmediately) {
                                    updateDates(setEndDate, setEndDateValid, enDate)
                                  }
                                }
                              }}/>
        </div>
      )}
      {children}
    </div>
  )
}
export default React.memo(DateRangePicker)
