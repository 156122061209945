import { DateTime } from 'luxon'
import validateEmail from './validateEmail'

async function validateBulkUploadData (api, dataArray) {
  if (!dataArray) {
    return { result: false, error: 'Data is null' }
  }

  if (dataArray.length <= 0) {
    return { result: false, error: 'Data is empty' }
  }

  for (var i = 0; i < dataArray.length; i++) {
    if (!dataArray[i]) {
      return { result: false, error: `Data line ${i + 1} is null` }
    }

    if (dataArray[i].length !== 11) {
      return { result: false, error: `Data line ${i + 1} contains insufficient fields` }
    }

    var data = dataArray[i]

    // 1. title (mandatory)
    if (!data[0] || (data[0].length === 0)) {
      return { result: false, error: `Data line ${i + 1}: Title is missing` }
    }
    // 2. start datetime (mandatory)
    var startDate = null
    if (!data[1] || (data[1].length === 0)) {
      return { result: false, error: `Data line ${i + 1}: Start Date is missing` }
    } else {
      console.log(data[1])
      console.log(DateTime.fromFormat(data[1], 'dd/MM/yyyy HH:mm'))
      //! check format
      if (DateTime.fromFormat(data[1], 'dd/MM/yyyy HH:mm').isValid) {
        startDate = DateTime.fromFormat(data[1], 'dd/MM/yyyy HH:mm').isValid
      } else {
        return { result: false, error: `Data line ${i + 1}: Start Date ${data[1]} is invalid` }
      }
    }
    // 3. end datetime (mandatory)
    var endDate = null
    if (!data[2] || (data[2].length === 0)) {
      return { result: false, error: `Data line ${i + 1}: End Date is missing` }
    } else {
      //! check format
      if (DateTime.fromFormat(data[2], 'dd/MM/yyyy HH:mm').isValid) {
        endDate = DateTime.fromFormat(data[2], 'dd/MM/yyyy HH:mm')
      } else {
        return { result: false, error: `Data line ${i + 1}: End Date ${data[2]} is invalid` }
      }
    }

    //! cross checking startDate has to be before endDate
    if (startDate > endDate) {
      return { result: false, error: `Data line ${i + 1}: Start Date has to be after End Date` }
    }

    // 4. activity (mandatory)
    if (!data[3] || (data[3].length === 0)) {
      return { result: false, error: `Data line ${i + 1}: Activity is missing` }
    } else {
      //! check range (skip for now)
    }

    // 5. short description (mandatory)
    if (!data[4] || (data[4].length === 0)) {
      return { result: false, error: `Data line ${i + 1}: Short Description is missing` }
    }
    // 6. long description (optional)
    // 7. Contacts (mandatory)
    if (!data[6] || (data[6].length === 0)) {
      return { result: false, error: `Data line ${i + 1}: Contact is missing` }
    } else {
      //! validate emails
      var emails = data[6].split(' ')
      for (const email of emails) {
        if (!validateEmail(email)) {
          return { result: false, error: `Data line ${i + 1}: Contact email ${email} is invalid` }
        }
      }
    }
    // 8. number of space (optional)
    if (data[7] && (data[7].length > 0)) {
      //! check digit value
      var x = parseFloat(data[7])
      if ((x | 0) !== x) {
        return { result: false, error: `Data line ${i + 1}: Space number has to be an integer` }
      }
    }
    // 9. attachments (optional)
    // 10. publish flag (optional)
    if (data[9] && (data[9].length > 0)) {
      //! check range
      var y = data[9].toLowerCase()
      if ((y !== 'yes') && (y !== 'no')) {
        return { result: false, error: `Data line ${i + 1}: Publish flag has to be either Yes or No` }
      }
    }
    // 11. location (mandatory)
    if (!data[10] || (data[10].length === 0)) {
      return { result: false, error: `Data line ${i + 1}: Location is missing` }
    } else {
      //! check http/https, also what3words format
      var locations = data[10].split(' ')
      //! allow only one online
      var online = null
      var physical = null
      for (const location of locations) {
        if (location.startsWith('https://') || location.startsWith('http://')) {
          if (online) {
            return { result: false, error: `Data line ${i + 1}: Only one online location is allowed` }
          }
          online = location
        } else {
          if (location.startsWith('///')) {
            if (physical) {
              return { result: false, error: `Data line ${i + 1}: Only one physical location is allowed` }
            }
            physical = location
          }
        }
      }

      if (!physical && !online) {
        return { result: false, error: `Data line ${i + 1}: Location is invalid` }
      }

      if (physical) {
        //! validate What3Words API
        const validAddress = await api.get(`/geo/what3words/validate?words=${physical.replace('///', '')}`)
        console.log('what3word validAddress=', validAddress)
        if (!validAddress || !validAddress.data) {
          return { result: false, error: `Data line ${i + 1}: Physical location is invalid` }
        }
      }
    }
  }

  return { result: true, error: null }
}

export default validateBulkUploadData
