const getVolunteeredHours = (op, userId) => {
  if (op.signups.length <= 0) return 0
  op.signups.forEach((x,i) => {
    if (x.user === (userId) && !x.cancelled) {
      return x.hours
    }
  })
}

export default getVolunteeredHours
