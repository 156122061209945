
const isBooked = (user, opportunity) => {
  if (user && opportunity) {
    const isBooked = opportunity.signups.filter(x => (x.user === user._id && !x.cancelled)).length > 0
    if (isBooked) {
      return true
    }
    return false
  }
}

export default isBooked
