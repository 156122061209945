import React, { useState, useEffect } from 'react'
import logo from '../assets/logoScs.png'
import logoWebp from '../assets/logo.webp'
import logoScs from '../assets/logoScs.png'
import { faSearch, faShare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { Button } from '@costain/react-ui'
import colors from './_colors'
import { css } from 'glamor'
  import { faComments } from '@fortawesome/free-solid-svg-icons'

function Sidebar ({ graphProfile, logout, isMobile, mobMenuOpen, setMobMenuOpen }) {
  const [active, setActive] = useState(0)
  const [sidebarShadow, setSideBarShadow] = useState(false)
  const location = useLocation()
  const { push } = useHistory()

  useEffect(() => {
    console.log(location.pathname)
    if (location.pathname.includes('opportunities')) setActive(0)
    if (location.pathname.includes('share')) setActive(1)

    if (location.pathname === '/opportunities' && !isMobile) {
      setSideBarShadow(true)
    } else {
      setSideBarShadow(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const style = css({
    zIndex: (sidebarShadow || mobMenuOpen) ? '999999' : '99',
    backgroundColor: '#fff',
    minWidth: isMobile ? '100%' : '253px',
    width: '253px',
    height: 'calc(100 * var(--vh))',
    boxShadow: '15px 4px 250px rgb(133 133 133 / 20%)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'scroll',
    '::-webkit-scrollbar': {
      display: 'none'
    }
  })

  const sidebarItems = [
    {
      title: 'Find',
      icon: faSearch,
      to: '/opportunities'
    },
    {
      title: 'Share',
      icon: faComments,
      to: '/share'
    }
  ]
  const scs = graphProfile ? graphProfile.mail.split('@')[1] === 'scsrailways.co.uk' : false
  return (
    <>
      {(!isMobile || mobMenuOpen) && (
        <div {...style}>
          {!isMobile && (
            <Link style={{alignSelf: scs ? 'center': 'auto'}} to='/'>
              <picture>
                {!scs && (
                  <source srcSet={logoWebp} type="image/webp"/>
                )}
                <source srcSet={scs ? logoScs : logo} type="image/jpeg"/>
                <img style={{  height: '78px', width: scs ? 'auto' : '104px', margin:scs ? '32px 0 32px 0' : '32px', opacity: graphProfile ? '1' :'0', transition: 'opacity 0.5s'}} src={logo} alt="Costain"/>
              </picture>
            </Link>
          )}

          <div style={{ marginTop: '100px', marginBottom: '50px' }}>
            {sidebarItems.map((item, index) => (
              <div key={item.title}>
                <div
                  onClick={() => {
                    push(item.to)
                    setMobMenuOpen(false)
                  }}
                  key={item.title}
                  style={{
                    height: '60px',
                    paddingLeft: '32px',
                    display: 'flex',
                    color: active === index ? colors.costainBlue : colors.costainDarkBlue,
                    boxShadow: active === index ? `inset 5px 0px 0px 0px ${colors.costainBlue}` : null,
                    backgroundColor: active === index ? '#f7f7f7':'#fff',
                    cursor: 'pointer'
                  }}
                >
                  <p
                    onMouseOver={(e) => e.stopPropagation()}
                    onMouseLeave={(e) => e.stopPropagation()}
                    style={{ fontSize: '20px', display: 'flex', alignSelf: 'center'}}
                  >
                    <FontAwesomeIcon icon={item.icon} style={{ marginRight: '18px', width: '24px', height: '24px' }} />
                    {item.title}
                  </p>
                </div>

                {active === index && item.sub && (
                  <div style={{
                    boxShadow: active === index ? `inset 5px 0px 0px 0px ${colors.costainBlue} ` : null,
                    paddingBottom: '1px'
                  }}
                  >
                    {/* {active === index && item.sub && item.sub.map((subItem,y)=> ( */}
                    {/*  <p style={{ */}
                    {/*    fontSize:'16px', */}
                    {/*    marginTop:0, */}
                    {/*    marginBottom:0, */}
                    {/*    paddingBottom:'10px', */}
                    {/*    paddingTop:'10px', */}
                    {/*    paddingLeft:'50px', */}
                    {/*    display:'flex', */}
                    {/*    alignSelf:'center', */}
                    {/*    boxShadow: active === index ?`inset 5px 0px 0px 0px ${colors.costainBlue}`:null, */}
                    {/*    color: getActiveChallengeType() === y ? colors.costainBlue : 'black', */}
                    {/*    backgroundColor:  getActiveChallengeType() === y ? '#CDE1F2' : 'white', */}
                    {/*    cursor:'pointer' */}
                    {/*  }} */}
                    {/*     onClick={() => push(subItem.to)} */}
                    {/*  >{subItem.title}</p> */}
                    {/* ))} */}
                  </div>
                )}

              </div>
            ))}
          </div>
          <div style={{ marginTop: 'auto', width: '100%', marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: 'column' }}>
            {
                <img alt='Profile' style={{backgroundColor: 'white', border: `1px solid ${colors.grey}`, width: '48px', height: '48px', borderRadius: '100%', marginLeft: 'auto', marginRight: 'auto', opacity: (graphProfile && graphProfile.pic) ? 1: 0,transition: 'opacity 0.5s' }} src={(graphProfile && graphProfile.pic) ? graphProfile.pic : null} />
            }
            <p style={{ textAlign: 'center', marginTop: '5px', opacity: graphProfile ? 1: 0,transition: 'opacity 0.5s'}}>{graphProfile && (graphProfile.displayName)}</p>
            <Button onClick={() => window.open("https://forms.office.com/Pages/ResponsePage.aspx?id=JJGwjMeyq02Jq7N4GqToCZarxEsqBWhOk7LSPEPjC-dUOUdTWklaUENBMDJJMUxCRTZKRDlUNUUzRy4u")} mouseOver={() => {}} mouseLeave={() => {}} textStyle={{ color: 'rgba(0, 0, 0, 0.5)' }} style={{ borderRadius: '0', boxShadow: 'none', width: '135px', background: 'white', marginBottom: '0px', border: '1px solid #000000', backgroundColor: 'white' }} title='Feedback' />
            <Button onClick={() => logout()} mouseOver={() => {}} mouseLeave={() => {}} textStyle={{ color: 'rgba(0, 0, 0, 0.5)' }} style={{ borderRadius: '0', boxShadow: 'none', width: '135px', background: 'white', marginBottom: '24px', border: '1px solid #000000', backgroundColor: 'white' }} title='Logout' />
          </div>
        </div>
      )}

    </>

  )
}

export default Sidebar
