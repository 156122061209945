import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import OpportunityMarker from './OpportunityMarker'

const OpportunityMap = ({ myOpsOpen, myOps, mobMap, isMobile,setQueryBounds, mapCenter,markerClick, currentUser,selectedOpportunity, opportunities, hovering, setHovering, userCoords, setMapCenter, mapZoom, setMapZoom }) => {
  const [mapReady, setMapReady] = useState(true)
  useEffect(() => {
    if (userCoords) {
      setMapReady(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCoords])

  const handleOnLoad = (map, maps) => {
    // const controlButtonDiv = document.createElement('div');
    // const x = (currentUser && currentUser.username) ? <AccountButton style={{marginTop:'24px',marginRight:'24px',boxShadow:'none',border:'1px solid #DCEAF5'}} onClick={() => push("/account")}/> : <Button style={{marginRight:'24px',marginTop:'24px'}} to="/login" title="Login"/>
    // ReactDOM.render(x, controlButtonDiv);
    // map.controls[maps.ControlPosition.RIGHT_TOP].push(controlButtonDiv);
  }
  return (
    <>
      <div style={{ width: '100%', height: '100vh', display: 'block'   }}>
        {mapReady && (
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleOnLoad(map, maps)}
            //TODO an authenticated user should request this key
            bootstrapURLKeys={{ key: 'AIzaSyDYOK8Us9yPxDR8V5u3eraNkkSZbAc1QKY' }}
            defaultCenter={[53.8008, -1.5491]}
            center={mapCenter}
            zoom={mapZoom}
            defaultZoom={7}
            options={
              {
                draggable: true,
                draggableCursor: 'default',
                draggingCursor: 'default',
                fullscreenControl: false,
                gestureHandling: 'greedy'
            }
            }
            onChange={({ zoom, center,bounds }) => {
              // setMapCenter(center)
              // setMapZoom(zoom)
              //    setMapLoc(center)
              if (setQueryBounds) {
                console.log(bounds)
                setQueryBounds(bounds)
              }
            }}

          >
            {(userCoords) && (
              <div
                lat={userCoords[1]}
                lng={userCoords[0]}
              >
                {/* <UserMarker/> */}
                {/* <UserDeliveryRadius */}
                {/*  radius={8000} */}
                {/*  zoom={zoom} */}
                {/*  lat={userCoords[1]} */}
                {/*  lng={userCoords[0]} */}
                {/* /> */}
              </div>
            )}
            {(selectedOpportunity && selectedOpportunity.activityLocation) && (
              <OpportunityMarker setHovering={() => {}} type={selectedOpportunity.activity[0]} key={selectedOpportunity._id} id={selectedOpportunity._id} hovering={selectedOpportunity._id} lat={selectedOpportunity.activityLocation.location.coordinates[1]} lng={selectedOpportunity.activityLocation.location.coordinates[0]} />
            )}
            {!myOpsOpen && opportunities && !selectedOpportunity && opportunities.map(opportunity => (
              opportunity.activityLocation && (
                <OpportunityMarker onClick={markerClick ? () => markerClick(opportunity) : null} setHovering={setHovering} type={opportunity.activity[0]} key={opportunity.filterType + opportunity._id} id={opportunity._id} hovering={hovering} lat={opportunity.activityLocation.location.coordinates[1]} lng={opportunity.activityLocation.location.coordinates[0]} />
              )
            ))}
            {!selectedOpportunity && myOpsOpen && myOps.map(opportunity => (
              opportunity.activityLocation && (
                <OpportunityMarker
                  setHovering={setHovering}
                  type={opportunity.activity[0]}
                  key={opportunity.filterType + opportunity._id}
                  id={opportunity._id}
                  hovering={hovering}
                  lat={opportunity.activityLocation.location.coordinates[1]}
                  lng={opportunity.activityLocation.location.coordinates[0]}
                />
              )
            ))}
          </GoogleMapReact>

        )}
      </div>

    </>
  )
}

export default OpportunityMap
