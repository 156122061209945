import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter as Router } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import LuxonUtils from '@date-io/luxon'

console.log('                       __        _\n' +
  '      _________  _____/ /_____ _(_)___\n' +
  '     / ___/ __ \\/ ___/ __/ __ `/ / __ \\\n' +
  '    / /__/ /_/ (__  ) /_/ /_/ / / / / /\n' +
  '    \\___/\\____/____/\\__/\\__,_/_/_/ /_/\n')

if (process.env.REACT_APP_ENV === 'production') {
  console.log = function () {}
}

ReactDOM.render(
  <React.StrictMode>
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Router>
          <App />
        </Router>
      </SnackbarProvider>
    </MuiPickersUtilsProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
