import React from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './draft-style.css'
import { colors } from '@costain/react-ui'

export default class RichInput extends React.Component {

  async componentDidMount() {
    let htmlText = await this.props.getHtmlText();

    //console.log('htmlText', htmlText);
    if (htmlText !== undefined) {
      const blocksFromHtml = htmlToDraft(htmlText);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      this.setState({
        editorState: EditorState.createWithContent(contentState)
      });
    }
  }

  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      colors: Object.values(colors)
    };

    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }


  onEditorStateChange = (editorState) => {
    this.setState({editorState});
    this.props.setHtmlText(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    //console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  render() {
    return (
      <Editor
        //wrapperClassName='demo-wrapper'
        //editorClassName='demo-editor'
        wrapperClassName='rich-input-wrapper'
        editorClassName='rich-input-editor'
        editorState={this.state.editorState}
        onEditorStateChange={this.onEditorStateChange}
        toolbar={{
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'remove', 'history'],
          colorPicker: {
            colors: this.state.colors,
          }
        }}
      />
    );
  }
}
