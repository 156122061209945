const getUser = async ({ id }, api) => {
  try {
    const user = await api.get(`/user/${id}`)
    return user.data
  } catch (e) {
    console.log(e)
    return null
  }
}

export default getUser
