import React from 'react'
import { css } from 'glamor'

// eslint-disable-next-line react/prop-types
const SmallChip = ({ style, value, img, onClick }) => {
  const defaultStyles = {

    margin: '0px',
    marginRight: '6px',
    fontSize: '12px',
    paddingLeft: '6px',
    marginTop: '6px',
    paddingRight: '6px',
    backgroundColor: '#efefef',
    color: 'black',
    display: 'inline',
    justifyContent: 'center',
    borderRadius: '10px'
  }

  const s = { ...defaultStyles, ...style }

  return (
    <p
      onClick={onClick}
      {...css(s)}
    >
      {img ? <span role='img' style={{}}>{img}</span> : null} <span style={{ alignSelf: 'center', marginLeft: '3px' }}>{value}</span>
    </p>
  )
}

export default SmallChip
