import React, { useEffect, useState } from 'react'
import MoonLoader from 'react-spinners/MoonLoader'
import ActivityFeed from '../../components/ActivityFeed'
import OpportunityCard from '../Opportunities/OpportunityCard'
import colors from '../../components/_colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'

function Activities ({
  user,
  graphProfile,
  api,
  graph,
  selectedOpportunity,
  setSelectedOpportunity,
  loading,
  setLoading,
  searchText,
  setSearchText,
  setHovering,
  setCenter,
  activities,
  setActivities,
  setZoom,
  fetchMoreData,
  hasMoreData,
  backToFirstPage,
  isMobile,
  setPage,
  page

}) {



  const [ locationKeys, setLocationKeys ] = useState([])
  const history = useHistory()

  useEffect(() => {
    return history.listen(location => {
      if (history.action === 'PUSH') {
        setLocationKeys([ location.key ])
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ _, ...keys ]) => keys)

          // Handle forward event

        } else {
          setLocationKeys((keys) => [ location.key, ...keys ])
          onSetSelectedOpportunity(null)


        }
      }
    })
  }, [ locationKeys, ])

  const handleHovering = (opportunity) => {
    if (selectedOpportunity) return
    if (opportunity) {
      setHovering(opportunity._id)
      if (opportunity.activityLocation &&
          opportunity.activityLocation.location &&
          opportunity.activityLocation.location.coordinates &&
          opportunity.activityLocation.location.coordinates.length >= 2) {
        setCenter(
          [opportunity.activityLocation.location.coordinates[1],
            opportunity.activityLocation.location.coordinates[0]])
        setZoom(8)
      }
    } else {
      setHovering(null)
      setCenter([53.8008, -1.5491])
      setZoom(7)
    }
  }

  const onSetSelectedOpportunity = (op) => {
    setSelectedOpportunity(op)
    backToFirstPage(op)
  }

  return (

    <>
      <div style={{
        display: 'flex',
        marginLeft: '32px',
        // marginTop:'22px',
        marginRight: '32px',
        alignItems: 'center',
        flexDirection: 'column'
      }}
      >
        <div style={{
          // marginTop: '10px',
          width: '100%',
          maxHeight: '240px',
          transition: 'height 0.2s ease-out'
        }}
        />

      </div>

      {selectedOpportunity !== null && (
        <div style={{ paddingLeft: '32px', paddingRight: '32px' }}>
          <div style={{ marginBottom: '20px', marginTop: '12px', cursor: 'pointer' }} onClick={() => onSetSelectedOpportunity(null)}>
            <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '3px' }} />
            {' Go Back'}
          </div>
          <OpportunityCard setHovering={setHovering} op={selectedOpportunity} />
          <div style={{ width: '1px', height: '25px', backgroundColor: '#e8e8e8', marginLeft: '10px', marginTop: '-5px', marginBottom: '-16px' }} />
        </div>
      )}

      <div
        id='scrollableDiv'
        style={{
          // paddingTop:'32px',
          paddingBottom: '32px',
          overflowY: 'scroll'
        }}
      >

        {
          activities &&
          (activities.length > 0) &&

            <ActivityFeed
              user={user}
              graphProfile={graphProfile}
              api={api}
              graph={graph}
              setActivities={setActivities}
              activities={activities}
              handleHovering={handleHovering}
              selectedOpportunity={selectedOpportunity}
              setSelectedOpportunity={setSelectedOpportunity}
              fetchMoreData={fetchMoreData}
              hasMoreData={hasMoreData}
              isMobile={isMobile}
              page={page}
              setPage={setPage}
            />
        }

        {(!loading && activities.length <= 0) && (
          <p style={{ fontWeight: 500, fontSize: '18px', color: colors.costainDarkBlue, textAlign: 'center' }}>
            No posts <span role='img' aria-label='shrug'>🤷</span>
          </p>
        )}
        {(loading || !user) && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <MoonLoader color={colors.costainBlue} size={18} />
          </div>
        )}
      </div>

    </>

  )
}

export default Activities
