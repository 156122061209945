import React, { useEffect, useRef, useState } from 'react'
import { format, register } from 'timeago.js/esm'
import _clone from 'lodash/clone'
import ReactHtmlParser from 'react-html-parser'
import './mention-style.css'
import colors from './_colors'
import Gallery from './Gallery'
import { css } from 'glamor'
import { faComment, faHeart } from '@fortawesome/free-regular-svg-icons'
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons'
import { Button }  from '@costain/react-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SmallChip from './SmallChip'
import { en_short } from 'timeago.js/lib/lang'
import TextArea from './TextArea'
import TaggableTextArea from './TaggableTextArea'
import getVolunteeredHours from '../utils/getVolunteeredHours'
import { IconButton } from '@costain/react-ui'

const ActivityPost =
({
  activity,
  activities,
  setActivities,
  handleHovering,
  api,
  user,
  index,
  setSelectedOpportunity,
  reply,
  setReply,
  graph,
  isMobile
}) => {
  const [commentHovering, setCommentHovering] = useState(false)
  const [likeHovering, setLikeHovering] = useState(false)
  const [newComment, setNewComment] = useState('')
  const [volHours, setVolHours] = useState(null)
  const ref = useRef(null)

  const commentActivity = async (activityId) => {
    const x = await api.put(`/activities/${activityId}/comment`, {
      content: newComment
    })
    const i = activities.map(activity => {
      if (activity._id === activityId) {
        activity.comments = x.data.comments
        activity.noComments = x.data.noComments
      }
      return activity
    })
    setNewComment('')
    setActivities(...[i])
  }

  const likeActivity = async (activityId) => {
    const x = await api.put(`/activities/${activityId}/like`)
    console.log(x)
    const i = activities.map(activity => {
      if (activity._id === activityId) {
        activity.likes = x.data.likes
        activity.noLikes = x.data.noLikes
      }
      return activity
    })
    console.log(i)
    setActivities(...[i])
  }

  const getProfilePicture = (activity) => {
    if (activity && activity.profilePicture) {
      return activity.profilePicture
    }
    return null
  }

  const getCreatedDate = (activity) => {
    if (activity &&
            activity.created) {
      register('en_short', en_short)
      return format(activity.created, 'en_short')
    }
    return ''
  }


  const swapTags = (text) => {
    let displayText = _clone(text)

    // e.g. @[George Chiao (George.chiao@costain.com)](xxxxx-xxxxx-xxxxx)
    const tags = text.match(/@\[.+?\]\(.+?\)/gi)

    if (tags) {
      // eslint-disable-next-line
            tags.map(foundText => {
        const tagInSquareBrackets = foundText.match(/@\[.+?\]/gi)

        if (tagInSquareBrackets && tagInSquareBrackets.length === 1) {
          const textInSquareBrackets = tagInSquareBrackets[0].slice(2, -1)
          const unwantedTextStartIndex = textInSquareBrackets.indexOf('(')
          const tagDisplayValue =
                        (unwantedTextStartIndex >= 0) ? textInSquareBrackets.slice(0, unwantedTextStartIndex) : textInSquareBrackets
          displayText = displayText.replace(foundText, `<a>${tagDisplayValue.trim()}</a>`)
        }
      })
    }

    return displayText
  }

  const getDescription = (activity) => {
    if (activity &&
            activity.description) {
      return swapTags(activity.description)
    }

    return ''
  }

  const getSummary = (activity) => {
    var author = 'Anonymous'

    if (activity &&
            activity.createdBy &&
            activity.createdBy.name) {
      author = activity.createdBy.name
    }

    return `${author}`
  }
  const getComment = (content) => {
    if (content) {
      return swapTags(content)
    }

    return ''
  }

  useEffect(() => {
    const x = getVolunteeredHours(activity.opportunity,user.id)
    setVolHours(x)
  },[activity])
  return (
    <>
      <div
        ref={ref} onClick={() => {setReply(activity._id)}} onMouseLeave={() => handleHovering()} onMouseEnter={() => handleHovering(activity.opportunity)} key={index} {...css({
        display: 'flex',
        flexDirection: 'row',
        padding: '1rem 0',
        margin: 0,
        background: '0 0',
        borderTop: '1px solid #f3f3f3f3',
        paddingLeft: '32px',
        paddingRight: '32px',
        transitionDuration: '0.2s',
        ':hover': {
          backgroundColor: '#f3f3f3',
          cursor: 'pointer'
        },
        backgroundColor: reply === activity._id ? '#f3f3f3' : 'none'
      })}
      >
        <div style={{}}>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'block', flex: '0 0 auto', width: '2.5em', height: 'auto', alignSelf: 'stretch', textAlign: 'left' }}>
              <img alt='Profile' style={{ width: '100%', height: 'auto', borderRadius: '500em' }} src={getProfilePicture(activity)} />
            </div>
            <div style={{ display: 'block', flex: '1 1 auto', alignSelf: 'stretch', textAlign: 'left', wordWrap: 'break-word' }}>
              <div style={{ margin: '0 0 .35714286em 1.14285714em', fontSize: '1em', fontWeight: 500 }}>
                {getSummary(activity)}
                <p style={{ display: 'inline-block', fontWeight: '400', fontSize: '.85714286em', margin: '0 0 0 .5em', color: 'rgba(0,0,0,.4)' }}>· {getCreatedDate(activity)}</p>
                <p style={{ color: colors.grey, margin: 0, fontWeight: 400 }}>{activity.opportunity.title}</p>
                <div className='activityDesc' style={{ fontSize: '14px', marginTop: '5px', lineHeight: '1.4285em', fontWeight: '400' }}>
                  {(volHours > 0) && (
                    <div style={{ marginBottom: '4px' }}>
                      <SmallChip style={{ background: colors.highlightYellow }} img='⏱️' value={`${volHours} hours logged`} />
                  </div>
                  )}

                  {ReactHtmlParser(getDescription(activity))}
                </div>
                {
                  (activity.attachments && (activity.attachments.length > 0)) && (
                    <Gallery style={{ marginTop: '8px' }} images={activity.attachments.map(i => i.link)} />
                  )
                }

                <div style={{display:'flex'}}>
                  <IconButton  onClick={(e) => {
                    e.stopPropagation()
                    setCommentHovering(false)
                    if (reply) setReply(null)
                    if (!reply) {
                      setReply(activity._id)
                      ref.current.scrollIntoView({ behavior: 'smooth' })
                    }}} icon={faComment} active={reply === activity._id} activeBackgroundColor={'rgba(0,156,222,0.1)'} activeIconColor={colors.brightBlue} label={activity.noComments}/>

                  <IconButton  onClick={(e) => {
                    e.stopPropagation()
                    setLikeHovering(false)
                    likeActivity(activity._id)
                  }} icon={user && activity.likes.includes(user._id) ? faHeartSolid : faHeart} active={activity.likes.includes(user._id)} activeBackgroundColor={'rgba(165,24,144,0.1)'} activeIconColor={colors.highlightPink} label={activity.noLikes}/>

                </div>
              </div>

            </div>
          </div>
          {reply === activity._id && (
            <>
              <div style={{ marginTop: '10px', fontSize: '12px', display: 'flex', marginLeft: '5em' }}>
                {!isMobile && (
                  <>
                    <TaggableTextArea placeholder='Type your reply as a comment' onClick={(e) => e.stopPropagation()} style={{ fontSize: '14px', width: '250px' }} value={newComment} handleChange={(e) => setNewComment(e.target.value)} graph={graph} />
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        commentActivity(activity._id)
                      }} style={{ maxHeight: '62px', height: '60px', margin: 0, marginLeft: '10px !important' }} textStyle={{ fontSize: '14px' }} title='Comment'
                    />
                  </>
                )}

                {isMobile && (
                  <div onClick={(e) => e.stopPropagation()} style={{ height: '160px', width: '100%', position: 'absolute', backgroundColor: 'white', bottom: 0, left: 0, zIndex: 99999999 }}>
                    <TaggableTextArea autofocus style={{ input: { paddingLeft: '15px', paddingRight: '15px', paddingTop: '10px' } }} placeholder='Type your comment' value={newComment} handleChange={(e) => setNewComment(e.target.value)} graph={graph} />
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        commentActivity(activity._id)
                      }} title='Comment'
                    />
                  </div>
                )}

              </div>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                {activity.comments.slice(0).reverse().map(comment => (
                  <div style={{ display: 'flex', marginTop: '10px' }}>
                    <div style={{ display: 'block', flex: '0 0 auto', width: '2.5em', height: 'auto', alignSelf: 'stretch', textAlign: 'left' }}>
                      {/* <img alt="Profile" style={{width:'100%',height:'auto',borderRadius:'500em'}} src={await getUserPicture({ id: comment.createdBy.adId }, api)}/> */}
                    </div>
                    <div style={{ display: 'block', flex: '1 1 auto', alignSelf: 'stretch', textAlign: 'left', wordWrap: 'break-word' }}>
                      <div style={{ margin: '0 0 .35714286em 1.14285714em', fontSize: '1em', fontWeight: 500 }}>
                        {comment.createdBy.name}
                        <p style={{ display: 'inline-block', fontWeight: '400', fontSize: '.85714286em', margin: '0 0 0 .5em', color: 'rgba(0,0,0,.4)' }}>{getCreatedDate(comment)}</p>
                        <p className='activityDesc' style={{ fontWeight: '300', marginTop: '3px', fontSize: '14px' }}>{ReactHtmlParser(getComment(comment.content))}</p>
                      </div>

                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

        </div>

      </div>
    </>
  )
}

export default ActivityPost
