import React from 'react'
import { css } from 'glamor'
import colors from './_colors'

// eslint-disable-next-line react/prop-types
const TextArea = ({ noHighlight, inputStyle, style, placeholder, onChange, onBlur, error, type, value, onFocus, disabled, onKeyDown, endAdorn, startAdorn, autoFocus, rows, highlight }) => {
  const inputDefaultStyle = {
    resize: 'none',
    height:'109px',
    width: '340px',
    display: 'block',
    fontFamily: 'Avenir',
    border: '1px solid #F1F1F1',
    boxSizing: 'border-box',
    borderRadius: '5px',
    fontSize: '18px',
    backgroundColor: '#fff',
    lineHeight: '22px',
    padding: '8px',
    paddingRight: endAdorn ? '40px' : '8px',
    color: !disabled ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.2)',
    '::placeholder': {
      color: 'rgba(0, 0, 0, .3)'
    },
    ':focus': {
      outline: !noHighlight ? `4px solid ${colors.highlightYellow}`: 'none',
      border: !noHighlight ?  '2px solid black' : '1px solid #F1F1F1',
      paddingLeft: startAdorn ? '40px' : '7px',
      borderRadius: '0px',
    },
    boxShadow: 'rgb(232, 232, 233) 15px 4px 150px',
    paddingLeft: startAdorn ? '40px' : '8px'
  }

  const errorStyles = {
    border: `2px solid ${colors.highlightRed}`
  }

  const containerDefaultStyle = {
    display: 'flex',
    alignSelf: 'center',
    height: inputDefaultStyle.height
  }
  const cont = { ...containerDefaultStyle, ...style }

  let s = { ...inputDefaultStyle, ...inputStyle }
  if (error) {
    s = { ...s, ...errorStyles }
  }

  return (
    <div style={cont}>
      <textarea
        rows={rows || '2'}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        disabled={disabled}
        onFocus={onFocus}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        {...css(s)}
        placeholder={placeholder}
        type={type || 'text'}
      />
      {endAdorn && (
        <div style={{ alignSelf: 'center', position: 'relative', marginLeft: `${-35}px`, zIndex: '100000' }}>
          {endAdorn}
        </div>
      )}
      {startAdorn && (
        <div style={{ alignSelf: 'center', position: 'absolute', marginLeft: '1px', zIndex: '100000' }}>
          {startAdorn}
        </div>
      )}

    </div>
  )
}

export default TextArea
