const getReadableActivity = (activity) => {
  if (activity === 'CHARITY_SUPPORT') return 'Charity Support'
  if (activity === 'COMMUNITY_PROJECT') return 'Community Project'
  if (activity === 'COVID_RECOVERY') return 'Covid-19 Recovery'
  if (activity === 'PROFESSIONAL_SERVICES') return 'Professional Services'
  if (activity === 'SPECIALIST_TRAINING') return 'Specialist Training'
  if (activity === 'STEM') return 'Education'
}

export default getReadableActivity
