const searchUsers = async (searchText, callback, graph) => {
  if (searchText.length <= 2) {
    return
  }

  return Promise.resolve(
    graph.get(`/users?$search="displayName:${searchText}"&$orderby=displayName&$count=true`,
      { headers: { ConsistencyLevel: 'eventual' } })
  )
    .then(res => {
      const users = []

      for (const user of res.data.value) {
        const userTag = {
          id: user.id,
          display: user.displayName + ' (' + user.mail + ')'
        }

        users.push(userTag)
      }

      return users
    })
    .then(users => {
      callback(users)
    })
}

export default searchUsers
