import React, {Fragment} from 'react'
import Select from 'react-select'
import colors from './_colors'


export default class DropdownButton extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
          selected: undefined
        };
      }

    handleChange = selected => {
        this.setState({ selected });
        this.props.setSelected(selected);
    };


    render() {

        const {selected} = this.state;
        const customStyles = {
            container: (provided, state) => ({
                ...provided,
                flex:3,
                width:'100%',
                fontFamily:'Avenir',
                minHeight:'36px',
                height:'36px',
                border:'none'
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected ? colors.costainBlue: 'white',
                color: state.isSelected ? 'white' : colors.costainDarkBlue,
                '&:hover': {
                    backgroundColor: colors.costainBlue,
                    color:'white'
                }
                //color: state.isSelected ? 'red' : 'blue',
            }),
            control: ({borderColor, boxShadow, ...provided}) => ({
                ...provided,
                border: '1px solid #FFFFFF',
                minHeight:'36px',
                height:'36px',
                fontSize:'16px',
                borderColor: 'none',
                '&:hover': {
                    borderColor: 'none'
                },
                // none of react-select's styles are passed to <Control />

            }),
            singleValue: (provided, state) => {


                return { ...provided};
            }
        }


        return (
            <Fragment>
                    <Select
                       styles={customStyles}
                        placeholder={this.props.placeholder}
                        isClearable="true"
                        isSearchable="true"
                        options={this.props.options}
                        value={selected}
                        onChange={this.handleChange}
                    />
            </Fragment>
        );
    }

}