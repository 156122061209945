import React, { useState } from 'react'
import './mention-style.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import Activity from './Activity'
const ActivityFeed =
({
  activities,
  setActivities,
  handleHovering,
  graph,
  api,
  user,
  setSelectedOpportunity,
  selectedOpportunity,
  hasMoreData,
  isMobile,
  page,
  setPage
}) => {
  const [reply, setReply] = useState(null)

  return (
    <div style={{ fontSize: '1rem', margin: '1em 0' }}>
      <InfiniteScroll
        dataLength={activities.length}
        next={() => {
          setPage(page + 1)
        }}
        hasMore={hasMoreData}
        scrollableTarget='scrollableDiv'
        scrollThreshold={0.8}
      >
        {
          user && (
            activities.map((activity, index) =>
              <Activity
                isMobile={isMobile}
                key={index}
                graph={graph}
                reply={reply}
                setReply={setReply}
                selectedOpportunity={selectedOpportunity}
                setSelectedOpportunity={setSelectedOpportunity}
                user={user}
                index={index}
                activities={activities}
                setActivities={setActivities}
                activity={activity}
                api={api}
                handleHovering={handleHovering}
              />
            )
          )
        }

      </InfiniteScroll>
      {
        // activities.map((activity, index) =>
        //   <ActivityPost
        //     graph={graph}
        //     reply={reply}
        //     setReply={setReply}
        //     selectedOpportunity={selectedOpportunity}
        //     setSelectedOpportunity={setSelectedOpportunity}
        //     user={user}
        //     index={index}
        //     activities={activities}
        //     setActivities={setActivities}
        //     activity={activity}
        //     api={api}
        //     handleHovering={handleHovering}/>
        // )
      }
    </div>
  )
}

export default ActivityFeed
