import React, { useEffect, useState } from 'react'
import OpportunityCard from './OpportunityCard'
import Input from '../../components/Input'
import { Checkbox, Modal } from '@material-ui/core'
import { Button } from '@costain/react-ui'
import api from '../../utils/api'
import { css } from 'glamor'

function LogTimeModal ({isMobile}) {
  const [confirmOps, setConfirmOps] = useState([])
  const [open, setOpen]= useState(false)
  const [opsToConfirm,setOpsToConfirm] = useState([])
  const [confirming, setConfirming] = useState(false)

  const getConfirmOps = async () => {
    try {
      const x = await api.get('/opportunities/confirm')
      const { data } = x
      setConfirmOps(data)
      console.log(data)
      return data
    } catch (e) {
      console.log('Error getting confirm ops')
    }
    return []
  }

  const initialOperation = async () => {
    const data = await getConfirmOps()
    console.log(data)
    if (data.length > 0) {
      setOpen(true)
    }
  }

  const handleChange = (op,hours) => {
    console.log(hours)
    console.log(op)
    console.log(opsToConfirm)
    let index = -1
    index = opsToConfirm.findIndex(x => x.id === op._id)
    if (index < 0) {
      setOpsToConfirm([...opsToConfirm,{id: op._id,hours}])
    } else {
      let newOps = opsToConfirm
      if (newOps[index].hours === null) {
        newOps[index] = {id: op._id,hours:0}
      } else {
        newOps[index] = {id: op._id,hours}
      }
      setOpsToConfirm([...newOps])
    }
  }

  const handleConfirmOpUpdate = async () => {
    setConfirming(true)
    await api.post(`/opportunities/hours`, {ops: opsToConfirm })
    setConfirming(false)
    setOpen(false)
  }

  useEffect(() => {
    initialOperation()
    // eslint-disable-next-line
  }, [])

  const style = css({
    '::-webkit-scrollbar': {
      display: 'none'
    },
    backgroundColor: 'white',
    width:!isMobile? '50%' : 'auto',
    height: isMobile? '100%':'auto',
    maxHeight:!isMobile?'50%' : '100%',
    marginLeft:'auto',
    marginRight:'auto',
    padding:'20px',
    borderRadius:'0px 0px 10px 10px', overflow:'scroll'
  })

  return (
    <>
      <Modal
        style={{ zIndex: 99999999}}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-attendance-modal"
        aria-describedby="simple-modal-description"
      >
        <div {...style}>
          <h2>Please log your time</h2>
          {confirmOps.map((op,i) => (
            <div style={{display:'flex',padding:'5px', flexDirection: isMobile? 'column': 'row'}}>
              <OpportunityCard style={{flex: 4}} op={op}/>
              <div style={{flex: 2, display: isMobile ? 'flex':'block', alignItems:'center'}}>
                <Input type="number" disabled={opsToConfirm[i] ? opsToConfirm[i].hours === null : false} value={opsToConfirm[i] ? opsToConfirm[i].hours : 0}  onChange={(e) => handleChange(confirmOps[i], e.target.value)} inputStyle={{width:'130px'}} style={{paddingLeft: isMobile ? '15px' : '9px'}} endAdorn="hours" endAddornMargin="-55px" placeholder="0" />
                <Checkbox style={{marginLeft: isMobile? '20px' :'0px'}} checked={opsToConfirm[i] ? opsToConfirm[i].hours === null : false} onChange={() => handleChange(confirmOps[i], null)} />
                <span onClick={() => handleChange(confirmOps[i], null)}  style={{cursor:'pointer'}}> Did not attend</span>
              </div>
            </div>
          ))}
          <Button loading={confirming} onClick={handleConfirmOpUpdate} title="confirm"/>
        </div>
      </Modal>
    </>

  )
}

export default LogTimeModal
