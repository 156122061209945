import React, { useEffect, useState } from 'react'
import ActivityCreationForm from '../../components/ActivityCreationForm'
import { asyncForEach } from '@costain/utils'
import searchUsers from '../../utils/searchUsers'

function NewActivity ({
  update,
  user,
  graphProfile,
  api,
  graph,
  getActivities
}) {
  const [activityReqPending, setActivityReqPending] = useState(false)
  const [opportunity, setOpportunity] = useState(null)
  const [opportunityOptions, setOpportunityOptions] = useState(null)
  // const [center, setCenter] = useState([53.8008,-1.5491]);
  // const [zoom, setZoom] = useState(12);

  useEffect(() => {
    if (!update && graphProfile) {
      const getOpportunities = async () => {
        const { data } = await api.get('/opportunities?started=true')
        handleSetOpportunityOptions(data.results)
      }
      getOpportunities()
    }
  // eslint-disable-next-line
  },[graphProfile]);

  const handleSetOpportunityOptions = async (opportunities) => {
    // setUserOpportunities(opportunities);

    const options = []

    opportunities.forEach(
      option =>
        options.push({ value: option._id, label: option.title, ref: option })
    )

    setOpportunityOptions(options)
  }

  const uploadAttachments = async (imageFiles) => {
    const files = []

    if (imageFiles && imageFiles.length > 0) {
      var docs = imageFiles.map(doc => doc.split(',')[1])

      await asyncForEach(imageFiles, async (file, index) => {
        const res =
            await api.post('/file',
              {
                filename: file.name,
                data: docs[index]
              })
        files.push({
          name: file.name,
          type: file.type,
          link: res.data.url
        })
      })
    }

    return files
  }

  const postActivity = async ({ description, mentions, imageFiles, hours }) => {
    console.log(opportunity)
    console.log(hours)
    if (!opportunity && !hours) {
      return
    }

    setActivityReqPending(true)

    uploadAttachments(imageFiles)
      .then((attachments) => {
        const request = {
          createdBy: graphProfile.id,
          opportunity: opportunity,
          description: description,
          mentions: mentions,
          attachments: attachments,
          hours
        }

        Object.keys(request)
          .forEach((key) => (
            (request[key] == null) || (request[key] === '')) && delete request[key]
          )

        return api.post(`/activities/${opportunity._id}`, request)
      })
      .then((activity) => {
        setActivityReqPending(false)
        getActivities()
      })
  }

  const handleSetOpportunity = selected => {
    if ((selected) && (selected.ref)) {
      console.log(selected)
      setOpportunity(selected.ref)

      if (selected.ref.activityLocation &&
          selected.ref.activityLocation.location &&
          selected.ref.activityLocation.location.coordinates &&
          selected.ref.activityLocation.location.coordinates.length >= 2) {

        // setCenter(
        //   [selected.ref.activityLocation.location.coordinates[1],
        //   selected.ref.activityLocation.location.coordinates[0]]);
        //
        // setZoom(16);
      }
    } else {
      setOpportunity(null)
    }
  }

  return (
    <>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          marginTop: '10px'
        }}
      >

        <>
          <div style={{ width: '100%' }}>

            <div style={{
              padding: '32px',
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: '12px',
              borderBottom: '1px solid #e8e8e8',
              paddingTop: '0px'
            }}
            >

              <ActivityCreationForm
                graph={graph}
                setSelected={handleSetOpportunity}
                opportunity={opportunity}
                opportunityOptions={opportunityOptions}
                searchUsers={searchUsers}
                update={update}
                updateActivity={null}
                postActivity={postActivity}
                activityReqPending={activityReqPending}
              />

            </div>

          </div>
        </>
      </div>
    </>

  )
}

export default NewActivity
