import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { css } from 'glamor'


const style = css({
    /* if backdrop support: very transparent and blurred */
    "@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))": {
      backgroundColor: 'rgba(255, 255, 255, .85) !important',
      '-webkit-backdrop-filter': 'blur(12px)',
      'backdrop-filter': 'blur(12px)'
  }
})



// eslint-disable-next-line react/prop-types
const Header = ({
  isMobile,
  setMobMenuOpen,
  mobMenuOpen
}) => {
  return (
    <>
      {isMobile && (
        <div style={{ width: '100vw', height: '52px', display: 'flex', alignItems: 'center', position: 'fixed', zIndex: '9999999', backgroundColor: 'rgba(255, 255, 255, .95)'}} {...style}>
          <FontAwesomeIcon
            onClick={() => {
              setMobMenuOpen(!mobMenuOpen)
            }} style={{ padding: '20px', fontSize: '1.5em', position: 'absolute', cursor: 'pointer', zIndex: '9999' }} icon={mobMenuOpen ? faTimes : faBars}
          />
          <h3 style={{ marginTop: 0, marginBottom: '4px', width: '100vw', textAlign: 'center', position: 'absolute' }}>Volunteer Hub <span role='img' aria-label='Raised Hand'>✋</span></h3>
        </div>
      )}
    </>
  )
}

export default Header
