const colors = {
  costainBlue: '#0067c6',
  costainDarkBlue: '#00234c',
  petrolBlue: '#00234c',
  midTeal: '#4f868e',
  white: '#fff',
  brightBlue: '#009cde',
  aqua: '#74b6ad',
  brightGreen: '#a1bf37',
  darkGreen: '#215732',
  darkRed: '#930041',
  darkOrange: '#be531c',
  orange: '#de7c00',
  ochre: '#bd9625',
  darkPurple: '#4d306b',
  purple: '#69589d',
  grey: '#d0d1d2',
  highlightBlue: '#00b5e2',
  highlightGreen: '#009639',
  highlightYellow: '#f6e200',
  highlightPink: '#a51890',
  highlightRed: '#c4192e'

}

export default colors
