const getUserById = async ({ id }, api) => {
  try {
    const { data } = await api.get(`/userbyid/${id}`)
    return data
  } catch (e) {
    console.log(e)
  }
}

export default getUserById
