import React from 'react'
import colors from './_colors'
import { Mention, MentionsInput } from 'react-mentions'
import searchUsers from '../utils/searchUsers'

function TaggableTextArea ({
  value,
  handleChange,
  onClick,
  style,
  graph,
  placeholder,
  autofocus
}) {
  const defaultStyle = {
    control: {
      resize: 'none',
      width: '100%',
      display: 'block',
      outline: 'none',
      fontFamily: 'Avenir',
      border: '1px solid #FFFFFF',
      boxSizing: 'border-box',
      borderRadius: '5px',
      fontSize: '16px',
      lineHeight: '16px',
      backgroundColor: '#fff',
      color: 'rgba(0, 0, 0, 0.8) !important',
      '::placeholder': {
        color: 'rgba(0, 0, 0, .3) !important'
      },
      ':focus': {
        outline: `4px solid ${colors.highlightYellow}`,
        border: '1px solid black',
      },
      boxShadow: 'rgb(232, 232, 233) 15px 4px 150px'
    },
    '&multiLine': {
      control: {
        minHeight: 63
      },
      highlighter: {
        padding: 9
      },
      input: {
        // padding: 9,
      },
      suggestions: {
        zIndex: 5,
        list: {
          backgroundColor: 'white',
          border: '1px solid rgba(0,0,0,0.15)',
          fontSize: 14,
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        },
        item: {
          padding: '5px 15px',
          borderBottom: '1px solid rgba(0,0,0,0.15)',
          '&focused': {
            color: 'white',
            backgroundColor: colors.costainBlue
          }
        }
      }
    }
  }

  const s = { ...defaultStyle, ...style }

  return (
    <MentionsInput
      autofocus={autofocus}
      style={s}
      allowSpaceInQuery
      value={value}
      placeholder={placeholder}
      onClick={onClick}
      onChange={handleChange}
      markup='@{{__type__||__id__||__display__}}'
      className='mentions'
    >
      <Mention
        style={{ backgroundColor: 'rgb(0 103 198 / 20%)' }}
        type='user'
        displayTransform={(id, display) => `${display.split('(')[0]}`}
        trigger='@'
        data={(x, callback) => searchUsers(x, callback, graph)}
        className='mentions__mention'
      />
    </MentionsInput>
  )
}

export default TaggableTextArea
