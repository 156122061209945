import React from 'react'
import ModalImage from 'react-modal-image'

const Gallery = ({
  images,
  removeImage,
  style
}) => {
  return (
    <div style={{ ...style, display: 'grid', gridTemplateColumns: 'repeat( auto-fit, minmax(100px, 1fr) )', gridTemplateRows: 'repeat(auto-fit)', gridGap: '12px' }}>
      {
        images.map((image, i) => (
          <div key={i} style={{ position: 'relative', display: 'flex' }}>
            <ModalImage className='gridImage' key={i} small={image} large={image} />
            {removeImage && (
              <div
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '100%',
                  backgroundColor: 'rgb(0,0,0,0.8)',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  position: 'absolute',
                  top: 0,
                  marginLeft: '5px',
                  marginTop: '5px'
                }}
                className='imageDelete'
                type='button'
                onClick={() => removeImage(i)}
              >
                <p style={{ color: 'white', marginLeft: 'auto', marginRight: 'auto' }}>
                  X
                </p>
              </div>
            )}
          </div>
        ))
      }

    </div>

  )
}

export default Gallery
