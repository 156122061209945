import React from 'react'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import getActivityIcon from '../../utils/getActivityIcon'
import { useHistory } from 'react-router-dom'
import colors from '../../components/_colors'

export default function OpportunityMarker ({ hovering, setHovering, id, type,onClick }) {
  const { push } = useHistory()
  return (
    <div onMouseEnter={() => setHovering(id)} onMouseLeave={() => setHovering(null)} style={{ display: 'flex' }} onClick={() => {
      if (onClick) {
        console.log(onClick)
        console.log("hello")
        onClick()
      } else {
        push(`/opportunities/${id}`)
      }
    }}>
      <FontAwesomeIcon
        style={{
          fontSize: '30px',
          color: colors.brightBlue,
          alignSelf: 'top',
          stroke: 'white',
          strokeWidth: '10',
          filter: hovering === id ? 'drop-shadow(0px 2px 1px rgb(36, 101, 175)' : 'none',
          cursor: 'pointer',
          transform: 'translate(-50%, -100%)',
        }} icon={faMapMarker}
      />
      <div style={{width:'18px',height:'18px',borderRadius:'100%',position: 'absolute',cursor: 'pointer',transform: 'translate(-50%, -155%)', backgroundColor:'white' }}>
        <span style={{ fontSize: '10px',position: 'absolute',transform: 'translate(25%)'}}>{getActivityIcon(type)}</span>
      </div>

    </div>
  )
}
