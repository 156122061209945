import React, { createRef, useEffect, useState } from 'react'
import api from '../../utils/api'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@costain/react-ui'
import SmallChip from '../../components/SmallChip'
import getReadableActivity from '../../utils/getReadableActivity'
import { format } from 'timeago.js/esm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faCalendar,
  faChevronLeft,
  faDirections,
  faEdit,
  faShare,
  faPaperclip,
  faLink, faMapMarkedAlt, faMapMarkerAlt, faUsers
} from '@fortawesome/free-solid-svg-icons'
import FadeIn from '../../components/FadeIn'
import getActivityIcon from '../../utils/getActivityIcon'
import getStatusColor from '../../utils/getStatusColor'
import colors from '../../components/_colors'
import parse from 'html-react-parser'
import ChipUtils from '../../utils/ChipUtils'
import getUserPicture from '../../utils/getUserPicture'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import getUserById from '../../utils/getUserById'
import { DateTime } from 'luxon'
import getStatus from '../../utils/getStatus'
import isBooked from '../../utils/isBooked'
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core'
import { useSnackbar } from 'notistack'


function Opportunity ({ setSelectedOpportunity, user, setCenter, setZoom, setMobMap, isMobile }) {
  const [opportunity, setOpportunity] = useState(null)
  const [booking, setBooking] = useState(false)
  const [booked, setBooked] = useState(false)
  const [displayMoreDetail, setDisplayMoreDetail] = useState(false)
  const { id } = useParams()
  const { push } = useHistory()
  const [contactChips, setContactChips] = useState([])
  const [attendanceChips, setAttendanceChips] = useState([])
  const [open, setOpen] = React.useState(false);
  const [openChip, setOpenChip] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const {enqueueSnackbar} = useSnackbar()

  const handleToggle = (chip, ref) => {
    setAnchorEl(ref.current)
    setOpenChip(chip)
    setOpen(!open);
  };


  const handleClose = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }
    setOpen(false);
    setOpenChip(null)
  };
  const handleCopyEmail = (e,text) => {
    console.log(e)
    if (anchorEl && anchorEl.contains(e.target)) {
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      console.log(`${text} copied to clipboard`)
    }, (err) => {
      console.log(`Error copying to clipboard`)
    })
      setOpen(false);
      setOpenChip(null)
  };

  const handleNoShow = async (e, id) => {
    //Should mark a attendee as a no-show

    if (anchorEl && anchorEl.contains(e.target)) {
      return;
    }
    const { data } = await api.post(`/opportunities/${opportunity._id}/attendance/${id}`)
    initSignups(data.signups)
    setOpen(false);
    setOpenChip(null)
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
      setOpenChip(null)
    }
  }
  const bookOpportunity = async (cancel) => {
    if (!cancel && opportunity.spacesRemaining && opportunity.spacesRemaining <= 0) {
      console.log('No space available')
      return
    }

    setBooking(true)
    try {
      const { data } = await api.post(`/opportunities/${id}/book`)
      console.log('booking data=', data)

      const isBooked = opportunity.signups.filter(x => (x.user === user._id && !x.cancelled)).length > 0
      if (!isBooked === true) enqueueSnackbar("Opportunity booked")
      if (!isBooked === false) enqueueSnackbar("Opportunity cancelled")

      console.log('isBooked', isBooked)
      setBooked(!isBooked)
      const x = { ...data, startDate: DateTime().fromJSDate(data.startDate), endDate: DateTime().fromJSDate(data.endDate) }
      console.log(x)
      setOpportunity(x)
    } catch (e) {
      console.log(e)
    }
    setBooking(false)
  }

  useEffect(() => {
    const booked = isBooked(user, opportunity)
    setBooked(booked)
  }, [user, opportunity])

  // eslint-disable-next-line no-unused-vars
  useEffect(() => {
    const getOpportunity = async () => {
      setMobMap(false)
      let x
      try {
        x = await api.get(`/opportunities/${id}`)
        // console.log('data',x)
        const { data } = x
        const y = { ...data, startDate: DateTime.fromISO(data.startDate), endDate: DateTime.fromISO(data.endDate) }
        setOpportunity(y)
        setSelectedOpportunity(y)
        initContacts(data.contacts)
        initSignups(data.signups)
        if (data.activityLocation) {
          setCenter([data.activityLocation.location.coordinates[1], data.activityLocation.location.coordinates[0]])
          setZoom(14)
        }
        return data
      } catch (e) {
        console.log(e)
      }
    }
    getOpportunity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, booked])

  const initContacts = async (cntcs) => {
    console.log(cntcs)
    let chips = []
    for (const own of cntcs) {
      console.log(own)
      if (own) {
        if (own.adId) {
          const pic = await getUserPicture({ id: own.adId }, api)
          console.log(pic)
          own.pic = pic
          chips = ChipUtils.addChipItem(own, chips)
        } else {
          chips = ChipUtils.addChipItem(own, chips)
        }
      }
    }

    console.log(chips)
    setContactChips(chips)
  }

  const initSignups = async (signups) => {
    console.log(signups)

    let chips = []

    for (const signup of signups) {
      console.log('signup=', signup)
      if (!signup.cancelled) {
        const own = await getUserById({ id: signup.user }, api)
        console.log('user=', own)
        if ((own) && (own.adId)) {
          const pic = await getUserPicture({ id: own.adId }, api)
          console.log(pic)
          own.pic = pic
          if (signup.attended === false) own.attended = false
          chips = ChipUtils.addChipItem(own, chips)
        } else {
          chips = ChipUtils.addChipItem(own, chips)
        }
      }
    }

    console.log(chips)
    setAttendanceChips(chips)
  }

  const getDiff = () => {
    const d = opportunity.endDate.diff(opportunity.startDate, ['hours', 'minutes']).toObject()
    console.log(d)
    return d.hours > 0 ? `${parseInt(d.hours)}h` : `${parseInt(d.minutes)}m`
  }

  let LiRefs = []

  return (
    <>
      <FadeIn>
        <div style={{ display: 'flex' }}>
          <p
            style={{ margin: 0, cursor: 'pointer' }} onClick={() => {
              setCenter([53.8008, -1.5491])
              setZoom(7)
              push('/opportunities')
            }}
          >
            <FontAwesomeIcon icon={faChevronLeft} style={{ marginRight: '3px' }} />
            {' Go Back'}
          </p>
          {opportunity && (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', marginRight: '6px' }}>
              {(user && (opportunity.createdBy._id === user._id)) && (
                <p style={{ margin: 0, marginLeft: '5px', fontSize: '10px', display: 'flex', alignSelf: 'center', color: getStatusColor(opportunity, (opportunity.createdBy._id === user._id)) }}>{getStatus(booked, opportunity.createdBy._id === user._id, opportunity)}</p>
              )}
            </div>
          )}
        </div>

        <div
          style={{
            alignItems: 'center',
            display: 'flex'
          }}
        >

          {opportunity && (
            <div style={{ display: 'flex', flexDirection: 'column', width:'100%' }}>
              <div>
                <h2 style={{ marginTop: '10px', marginBottom: '4px' }}>{opportunity.title}</h2>
              </div>
              <div style={{ marginBottom: '8px', fontSize: '13px' }}>
                {((opportunity.endDate).startOf('day') <= (opportunity.startDate).startOf('day')) && (
                  <>
                    <b style={{ fontWeight: 500 }}>{(opportunity.startDate).toFormat('ccc dd MMMM')}</b> ·  <b style={{ fontWeight: 500 }}>{(opportunity.startDate).toFormat('HH:mm')} - {(opportunity.endDate).toFormat('HH:mm')}</b> ({getDiff()})
                  </>
                )}
                {((opportunity.endDate).startOf('day') > (opportunity.startDate).startOf('day')) && (
                  <>
                    <b style={{ fontWeight: 500 }}>{opportunity.startDate.toFormat('ccc dd MMMM')}</b> · <b style={{ fontWeight: 500 }}>{(opportunity.startDate).toFormat('HH:mm')}  <FontAwesomeIcon style={{ marginLeft: '5px', marginRight: '8px' }} size='xs' icon={faArrowRight} />
                      {opportunity.endDate.toFormat('ccc dd MMMM')}
                    </b> ·  <b style={{ fontWeight: 500 }}>{(opportunity.endDate).toFormat('HH:mm')}</b>
                  </>
                )}
              </div>
              <div style={{ display: 'flex' }}>
                <SmallChip img={getActivityIcon(opportunity.activity[0])} value={`${getReadableActivity(opportunity.activity[0])}`} style={{ marginTop: '0px' }} />
                {opportunity.noSpaces && !opportunity.completed && (
                  <p style={{ margin: 0, fontSize: '10px', display: 'flex', alignItems: 'center' }}> {opportunity.spacesRemaining} {opportunity.spacesRemaining === 1 ? 'space' : 'spaces'} remaining </p>
                )}
                {(user && (opportunity.createdBy._id !== user._id)) && (
                  <p style={{ margin: 0, marginLeft: '5px', fontSize: '10px', display: 'flex', alignSelf: 'center' }}>{format(opportunity.created)}</p>
                )}
                {opportunity.completed && (
                  <SmallChip style={{ background: colors.highlightYellow, marginTop: '0px' }} img='⏱️' value={`${opportunity ? (opportunity.hoursLogged) : 0} hours logged`} />
                )}

              </div>

              <div style={{ marginTop: '8px' }}>
                {
                  contactChips.map(chip =>
                    <Chip
                      key={chip.id || chip._id}
                      style={{
                        marginLeft: '3px',
                        marginRight: '3px',
                        marginTop: '3px',
                        backgroundColor: colors.aqua
                      }}
                      avatar={chip.pic && (<Avatar src={chip.pic}>M</Avatar>)}
                      label={chip.name || chip.displayName || chip}
                      color='primary'
                    />
                  )
                }
              </div>
              {
                (opportunity.supportingDocumentation.length > 0) &&
                  opportunity.supportingDocumentation.map(x =>
                    (<div style={{ display: 'flex', marginTop: '12px' }}>
                      <FontAwesomeIcon
                        color='black'
                        style={{ margin: '5px' }}
                        size='xs'
                        icon={faPaperclip}
                      />
                      <a
                        href={x.link}
                        download={x.name}
                        style={{ fontSize: '10px', margin: '5px' }}
                      >{x.name}
                      </a>
                    </div>)
                  )
              }
              <div>

                {opportunity.shortDescription && (
                  <p style={{ marginBottom: '5px' }}>{opportunity.shortDescription}</p>
                )}

                {opportunity.longDescription && displayMoreDetail &&
                  parse(opportunity.longDescription)}<br />

                <span>
                  {opportunity.longDescription && (
                    <button
                      style={{
                        background: 'none',
                        border: 'none',
                        outline: 'none',
                        color: 'blue',
                        cursor: 'pointer',
                        display: 'inline-flex',
                        paddingBottom: '10px',
                        paddingLeft: '0px'
                      }}
                      onClick={() => setDisplayMoreDetail(!displayMoreDetail)}
                    >{displayMoreDetail ? 'Read Less' : 'Read More'}
                    </button>
                  )}
                </span>
              </div>

              {booked && (
                <span>

                  <h3 style={{ marginBottom: '5px', fontSize: '16px' }}>  <FontAwesomeIcon
                    color='black'
                    style={{ marginRight: '5px' }}
                    size='xs'
                    icon={faMapMarkerAlt}
                                                                          />Location
                  </h3>
                </span>

              )}

              {booked && opportunity.activityUrl && (
                <div style={{ display: 'flex', paddingTop: '10px', paddingBottom: '10px' }}>
                  <FontAwesomeIcon
                    color='black'
                    style={{ marginRight: '5px' }}
                    size='xs'
                    icon={faLink}
                  />
                  <a target='_blank' rel='noopener noreferrer' href={opportunity.activityUrl}>Join Teams Meeting</a>
                </div>
              )}

              {booked && (
                <div style={{ marginBottom: '8px' }}>
                  {opportunity.activityLocation && opportunity.activityLocation.line1 && (
                    <p style={{ margin: 0, color: colors.grey }}>{opportunity.activityLocation.line1}</p>
                  )}
                  {opportunity.activityLocation && opportunity.activityLocation.line2 && (
                    <p style={{ margin: 0, color: colors.grey }}>{opportunity.activityLocation.line2}</p>
                  )}
                  {opportunity.activityLocation && opportunity.activityLocation.town && (
                    <p style={{ margin: 0, color: colors.grey }}>{opportunity.activityLocation.town}</p>
                  )}
                  {opportunity.activityLocation && opportunity.activityLocation.postCode && (
                    <p style={{ margin: 0, color: colors.grey }}>{opportunity.activityLocation.postCode}</p>
                  )}
                </div>
              )}

              <h3 style={{ marginTop: '12px', marginBottom: '5px', fontSize: '16px' }}>
                <FontAwesomeIcon
                  color='black'
                  style={{ marginRight: '5px' }}
                  size='xs'
                  icon={faUsers}
                />Attendees
              </h3>
              <div>
                {openChip && (
                  <Popper style={{zIndex:'99999999'}} open={open} anchorEl={anchorEl} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={true} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                              <MenuItem  onClick={(e) => handleCopyEmail(e, openChip.email)}>Copy email</MenuItem>
                              {(opportunity.createdBy._id === user._id) && (
                                <MenuItem selected={openChip.attended === false ? true : false} onClick={(e) => handleNoShow(e,openChip._id)}>No show</MenuItem>
                              )}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                )}
                {
                  (attendanceChips.length > 0)
                    ? attendanceChips.map(chip => {
                      const newRef = createRef();
                      return (
                        <Chip
                          ref={newRef}
                          aria-controls={open ? 'menu-list-grow' : undefined}
                          aria-haspopup="true"
                          onClick={(() => handleToggle(chip,newRef))}
                          key={chip.id || chip._id}
                          style={{
                            marginLeft: '3px',
                            marginRight: '3px',
                            marginTop: '3px',
                            backgroundColor: chip.attended === false ? colors.darkRed : '#efefef',
                            color:  chip.attended === false ? 'white' : 'black',
                            textDecoration: chip.attended === false ? 'line-through' : 'none'
                          }}
                          avatar={chip.pic && (<Avatar src={chip.pic}>M</Avatar>)}
                          label={chip.name || chip.displayName || chip}
                        />
                      )
                    }

                    ) : 'No one yet - signup now!'

                }
              </div>

              <div style={{ display: 'flex', marginTop: '8px', flexDirection: isMobile? 'column': 'row'}}>

                {(user && (opportunity.createdBy._id === user._id)) && (
                  <Button to={`/opportunities/${opportunity._id}/edit`} icon={<FontAwesomeIcon style={{ marginRight: '3px' }} icon={faEdit} />} style={{ marginLeft: '0px', marginRight: isMobile? '0px' : '4px' }} title={isMobile ? 'edit': ''} />
                )}

                {(opportunity.completed) && (
                  <Button to={`/share/${opportunity._id}`} icon={<FontAwesomeIcon style={{ marginRight: '3px' }} icon={faShare} />} style={{ marginLeft: '0px', marginRight: isMobile? '0px' : '4px' }} title='Share' />
                )}

                {!opportunity.completed && opportunity.status !== 'DRAFT' && (
                  <>
                    {(!booked) && (
                      <Button disabled={opportunity.spacesRemaining && opportunity.spacesRemaining <= 0} loading={booking} style={{ marginLeft: '0px' }} title='Book Now' onClick={() => bookOpportunity()} />
                    )}
                    {booked && (
                      <>
                        {opportunity.activityLocation && (
                          <Button tooltip="Get Directions" onClick={() => window.open(`https://www.google.com/maps/dir/?api=1&destination=${opportunity.activityLocation.location.coordinates[1]},${opportunity.activityLocation.location.coordinates[0]}`)} icon={<FontAwesomeIcon style={{ marginRight: '3px' }} icon={faDirections} />} style={{ marginLeft: '0px', marginRight: isMobile? '0px' : '4px' }} title={isMobile ? 'Diretions': ''} />
                        )}
                        <Button  style={{ marginLeft: '0px', marginRight: isMobile? '0px' : '4px' }}loading={booking}  title='Cancel Booking' onClick={() => bookOpportunity(true)} />
                      </>
                    )}
                  </>
                )}

              </div>

            </div>
          )}

        </div>
      </FadeIn>

    </>

  )
}

export default Opportunity
