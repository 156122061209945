import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Input from '../components/Input'
import arti1 from '../assets/arti1.png'
import arti2 from '../assets/arti2.png'
import logo from '../assets/logo.png'
import logoWebp from '../assets/logo.webp'
import InputEnterButton from '../components/InputEnterButton'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import MoonLoader from 'react-spinners/MoonLoader'
import colors from '../components/_colors'
import { Button } from '@costain/react-ui'
import * as PropTypes from 'prop-types'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { Link } from '@material-ui/core'

class FontAwesomeIcon extends React.Component {
  render () {
    return null
  }
}

FontAwesomeIcon.propTypes = { icon: PropTypes.any }

function Login ({ graph, graphProfile, onSignIn, account, api }) {
  const [checking, setChecking] = useState(false)
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [name, setName] = useState('')
  const [signup, setSignup] = useState(false)
  const [signingUp, setSigningUp] = useState(false)
  const [invited, setInvited] = useState(false)
  const [pendingAccept, setPendingAccept] = useState(false)
  const { pathname } = useLocation()

  const validateEmail = (emailAddress) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(emailAddress).toLowerCase())
  }
  const sendInvite = async() => {
    setSigningUp(true)
    try {
      const invite = await api.post(`${process.env.REACT_APP_API_URL}/user/invite`, {
        name,
        email,
      })
      setInvited(true)
      console.log(invite)
    } catch (e) {
      console.log(e)
    }

    setSigningUp(false)
  }
  const checkExists = async (email) => {
    setError(false)
    setChecking(true)
    const domain = email.split('@')[1]
    if (!validateEmail(email)) {
      setChecking(false)
      return
    }
    if (domain.toLowerCase() === 'costain.com' || domain.toLowerCase() === 'workingwithcostain.com') {
      return onSignIn({ email, redirect: pathname })
    }

    if (domain.toLowerCase() === "scsrailways.co.uk" || domain.toLowerCase() === "ed.ac.uk") {
      const {data} = await api.get(`${process.env.REACT_APP_API_URL}/user/email/${email}`)
      console.log(data)
      if (data.pending) {
        setInvited(true)
        setChecking(false)
        await sendInvite()
        return
      }
      if (data.exists) return onSignIn({email,redirect:pathname})
      setSignup(true)
    }

    setChecking(false)
  }
  return (
    <div style={{ width: '100%', height: 'calc(100 * var(--vh))' }}>
      <img alt='' style={{ position: 'absolute', right: '0', height: '100%' }} src={arti1} />
      <img alt='' style={{ position: 'absolute', right: '0', height: '100%' }} src={arti2} />

      <picture>
        <source srcSet={logoWebp} type="image/webp"/>
          <source srcSet={logo} type="image/jpeg"/>
          <img style={{ position: 'absolute', width: '104px', height: '78px', right: '0', margin: '40px' }} src={logo} alt="Costain"/>
      </picture>

      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto', width: '100%' }}>
        <>

          {!invited && (
            <>
              <h2 style={{
                marginTop: '200px',
                textAlign: 'center',
                zIndex: '9999'
              }}
              >Volunteer Hub
              </h2>
            <Input
              value={email}
              autoFocus
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              onKeyDown={(async (e) => {
                if (e.keyCode === 13) await checkExists(e.target.value)
              })}
              endAdorn={
                !signup ? (!checking) ? (<InputEnterButton onClick={async (e) => await checkExists(email)} style={{ opacity: email.length > 0 ? 1 : 0.3, marginTop: '6px' }} icon={faArrowRight} />) : (<MoonLoader css={{ marginTop: '6px' }} size={16} />): null
              }
              inputStyle={{
                border: error ? '1px solid red' : '1px solid rgba(0,0,0,0.6)',
                ':focus': {
                  border: error ? '1px solid red' : `1px solid ${colors.brightBlue}`
                },
                height: '42px'
              }}
              style={{ zIndex: '9999', width: '340px' }} placeholder='Email Address'
            />
          {signup && (
            <>
            <Input  inputStyle={{
            border: error ? '1px solid red' : '1px solid rgba(0,0,0,0.6)',
            ':focus': {
            border: error ? '1px solid red' : `1px solid ${colors.brightBlue}`
          },
            height: '42px'
          }} value={name} onChange={(e) => setName(e.target.value)} style={{ zIndex:'9999',marginTop:'10px'}} placeholder='Name'/>
            <Button onClick={sendInvite} disabled={invited} start loading={signingUp} style={{width:'340px', maxWidth:'340px', padding:0}} title="Sign up"/>

            </>
            )}
            </>
          )}

          {(invited) && (
            <div style={{textAlign:'center', marginTop:'200px', zIndex:'1000001'}}>
              <FontAwesomeIcon icon={faCheckCircle}/>
              <h3 style={{marginBottom:'10px',marginTop:'10px'}}>Invite sent</h3>
              <p style={{marginTop:'3px'}}>Check your email</p>
              <Link onClick={() => {
                setInvited(false)
                setSignup(false)
                setEmail('')
              }}>Login</Link>
            </div>
          )}
        </>
      </div>

    </div>
  )
}

export default Login
