import React from 'react'
import { css } from 'glamor'
import MoonLoader from 'react-spinners/MoonLoader'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function InputEnterButton ({
  loading,
  onClick,
  title,
  style,
  textStyle,
  mouseOver,
  mouseLeave,
  icon,
  lowercase,
  to,
  type,
  disabled
}) {
  const defaultStyles = {
    height: '24px',
    width: '24px',
    backgroundColor: 'none',
    border: '1px solid grey',
    borderRadius: '100%',
    display: 'flex',
    cursor: 'pointer'
  }
  const s = { ...defaultStyles, ...style }

  const { push } = useHistory()

  return (
    <div
      type='submit'
      onClick={(e) => {
        onClick && onClick(e)
        push(to)
      }}
      {...css(s)}
    >
      <div
        style={{ display: 'inherit', margin: 'auto' }}
      >
        {loading && (
          <MoonLoader css={{ margin: 0, marginRight: '6px' }} size={16} />
        )}
        <FontAwesomeIcon style={{ zIndex: '100001', marginLeft: 'auto', marginRight: 'auto', marginTop: 'auto', marginBottom: 'auto' }} icon={icon} />
      </div>
    </div>
  )
}

export default InputEnterButton
