import React from 'react'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import colors from './_colors'

export default function LocationMarker ({ hovering, id }) {
  return (
    <FontAwesomeIcon
      onMouseOver={(e) => { e.target.style.opacity = 1 }}
      onMouseLeave={(e) => hovering ? e.target.style.opacity = 0.6 : 1}
      alt='Location'
      style={{
        fontSize: '32px',
        transform: 'translate(-50%, -100%)',
        opacity: hovering === id ? '100%' : '60%',
        color: colors.darkRed
      }}
      icon={faMapMarkerAlt}
    />
  )
}
