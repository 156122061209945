const getActivityIcon = (activity) => {
  if (activity === 'CHARITY_SUPPORT') return '🎗️'
  if (activity === 'COMMUNITY_PROJECT') return '🌱'
  if (activity === 'COVID_RECOVERY') return '💉'
  if (activity === 'PROFESSIONAL_SERVICES') return '👔'
  if (activity === 'SPECIALIST_TRAINING') return '👩‍🚀'
  if (activity === 'STEM') return '📐'
}

export default getActivityIcon
