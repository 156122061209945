import React, { useRef, useState } from 'react'
import { format, register } from 'timeago.js/esm'
import _clone from 'lodash/clone'
import ReactHtmlParser from 'react-html-parser'
import './mention-style.css'
import colors from './_colors'
import Gallery from './Gallery'
import { css } from 'glamor'
import { faComment, faHeart } from '@fortawesome/free-regular-svg-icons'
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SmallChip from './SmallChip'
import { en_short } from 'timeago.js/lib/lang'
import TextArea from './TextArea'
import { Button } from '@costain/react-ui'
import TaggableTextArea from './TaggableTextArea'
import getVolunteeredHours from '../utils/getVolunteeredHours'

const OpportunityPost =
({
  opportunity,
  handleHovering,
  index,
  setSelectedOpportunity,
  complete
}) => {

  const getCompletedDate = (activity) => {
    if (activity &&
          activity.filterDate) {
      register('en_short', en_short)
      return format(activity.filterDate, 'en_short')
    }
    return ''
  }
  return (
    <>

      {opportunity && (
        <div
          onClick={() => setSelectedOpportunity(opportunity)} onMouseLeave={() => handleHovering()} onMouseEnter={() => handleHovering(opportunity)} key={index} {...css({
          display: 'flex',
          flexDirection: 'row',
          padding: '1rem 0',
          margin: 0,
          background: '0 0',
          borderTop: '1px solid #f3f3f3f3',
          paddingLeft: '32px',
          paddingRight: '32px',
          transitionDuration: '0.2s',
          ':hover': {
            backgroundColor: '#f3f3f3',
            cursor: 'pointer'
          }
        })}
        >
          <div style={{}}>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'block', flex: '1 1 auto', alignSelf: 'stretch', textAlign: 'left', wordWrap: 'break-word' }}>
                <div style={{ margin: '0 0 .35714286em 1.14285714em', fontSize: '1em', fontWeight: 500 }}>
                  <div style={{ marginTop: '10px' }}>
                    {(complete) && (
                      <>
                        Volunteering Completed <span role='img' aria-label='celebrate'>🎉</span>
                      </>
                    )}
                    {!complete && (
                      <>
                        Opportunity Posted <span role='img' aria-label='check'>✅</span>
                      </>
                    )}
                    <p style={{ display: 'inline-block', fontWeight: '400', fontSize: '.85714286em', margin: '0 0 0 .5em', marginLeft: '10px', color: 'rgba(0,0,0,.4)' }}>· {getCompletedDate(opportunity)}</p>
                    <p style={{ marginRight: '15px', marginTop: 0, display: 'flex', alignItems: 'center', fontWeight: 200 }}>
                      {opportunity.title}
                    </p>
                    {/*<SmallChip style={{ background: colors.highlightYellow }} img='⏱️' value={`${opportunity ? opportunity.hoursLogged : opportunity.hours} hours logged total`} />*/}
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>

      )}

    </>
  )
}

export default OpportunityPost
