const getUserPicture = async ({ id, user }, api) => {
  try {
    const cachedImg = localStorage.getItem(id)
    if (cachedImg !== null) {
      return cachedImg
    }
    if (user && user.pic) {
      return `data:image/png;base64, ${user.pic}`
    } else {
      const { data } = await api.get(`/user/${id}/photo`)
      localStorage.setItem(id, data)
      if (data) return data
      return null
    }
  } catch (e) {
    return null
  }
}

export default getUserPicture
