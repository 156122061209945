import React from 'react'
import { css } from 'glamor'

// eslint-disable-next-line react/prop-types
const Label = ({ text, error, color, style, explainer }) => {
  const defaultStyles = {
    color: color || '#fff',
    fontSize:'18px',
    fontWeight: 'bold',
    marginBottom: explainer ? '5px' : 'revert',
  }

  const s = { ...defaultStyles, ...style }
  return (
    <>
      <p
        {...css(s)}
      >
        {text}
      </p>
      {explainer && (
        <p style={{marginTop:0, color:'rgba(0,0,0,0.4)'}}>
          {explainer}
        </p>
      )}

    </>

  )
}

export default Label
