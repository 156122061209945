import { UserAgentApplication, Logger, LogLevel } from 'msal'

export const requiresInteraction = errorMessage => {
  if (!errorMessage || !errorMessage.length) {
    return false
  }

  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1
  )
}

export const fetchMsGraph = async (url, accessToken) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

  return response.json()
}

export const fetchMsGraphPhoto = async (url, accessToken) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  if (response.status === 200) {
    return response.blob()
  }
}

export const isIE = () => {
  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ') > -1
  const msie11 = ua.indexOf('Trident/') > -1

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11
}

export const GRAPH_SCOPES = {
  API: `api://${process.env.REACT_APP_AZURE_AD_CLIENT_ID}/access_as_user`,
  OPENID: 'openid',
  PROFILE: 'profile',
  USER_READ: 'User.Read',
  USER_READ_ALL: 'User.Read.All',
  MAIL_READ: 'Mail.Read'
}

export const GRAPH_ENDPOINTS = {
  ME: 'https://graph.microsoft.com/v1.0/me',
  MAIL: 'https://graph.microsoft.com/v1.0/me/messages'
}

export const GRAPH_REQUESTS = {
  LOGIN: {
    scopes: [
      GRAPH_SCOPES.PROFILE,
      GRAPH_SCOPES.OPENID,
      GRAPH_SCOPES.USER_READ
    ]
  },
  EMAIL: {
    scopes: [GRAPH_SCOPES.MAIL_READ]
  },
  API: {
    scopes: [GRAPH_SCOPES.API]
  }
}

export const msalApp = new UserAgentApplication({
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_AUTHORITY}`,
    redirectUri: process.env.REACT_APP_DEPLOY_URL,
    validateAuthority: true,
    postLogoutRedirectUri: process.env.REACT_APP_DEPLOY_URL,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: isIE()
  },
  system: {
    navigateFrameWait: 500,
    logger: new Logger((logLevel, message) => {
      // console.log(message);
    }, {
      level: LogLevel.Verbose,
      piiLoggingEnabled: true
    }),
    telemetry: {
      applicationName: 'costain-volunteer-hub',
      applicationVersion: '1.0.0',
      telemetryEmitter: (events) => {
        // console.log('Telemetry Events:', events);
      }
    }
  }
})
