/* global localStorage */
import React, { useEffect, useState } from 'react'
import Sidebar from './components/Sidebar'
import {
  Switch,
  Route,
  Redirect,
  useHistory
} from 'react-router-dom'
import AuthProvider from './AuthProvider'
import useWindowDimensions from './utils/useWindowDimensions'
import Login from './views/Login'
import MoonLoader from 'react-spinners/MoonLoader'
import Opportunities from './views/Opportunities/Opportunities'
import NewOpportunity from './views/Opportunities/NewOpportunity'
import Feed from './views/Share/Feed'
// import ActivityPanel from './views/Activities/ActivityPanel'
// import NewActivity from './views/Activities/NewActivity'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import colors from './components/_colors.js'
import Header from './components/Header'
import { Settings } from 'luxon'
import { css } from 'glamor'

// Configure the time zone

function App ({ account, onSignOut, onSignIn, graphProfile, user, apiKey, graphKey, api, graph, loggingIn }) {
  const { width, height } = useWindowDimensions()
  const [isMobile, setIsMobile] = useState(false)
  const [mobMenuOpen, setMobMenuOpen] = useState(false)
  const { push } = useHistory()
  Settings.defaultZone = 'Europe/London'
  const theme = createMuiTheme({
    palette: {
      primary: {
        main: colors.costainBlue
      },
      secondary: {
        main: colors.costainDarkBlue
      }
    },
    overrides: {
      // Style sheet name ⚛️
      MuiInputBase: {
        root: {
          padding: '8px',
          height: '36px',
          border: '1px solid #FFF',
          fontFamily: 'Avenir',
          borderRadius: '5px'
        }
      },
      MuiTextField: {
        // Name of the rule
        root: {
          // Some CSS
          height: '36px',
          border: '1px solid #F1F1F1',
          backgroundColor: 'white',
          borderRadius: '5px',
          '& .Mui-focused': {
            outline: `4px solid ${colors.highlightYellow}`,
            border: '2px solid black',
            borderRadius: '0px'
          }

        }

      },

      MuiSelect: {
        root: {
          padding: '0px'
        },
        select: {
          '&:focus': {
            backgroundColor: 'none'
          }
        }

      }
    }

  })

  useEffect(() => {
    if (apiKey) {
      const r = localStorage.getItem('redirect')
      localStorage.setItem('redirect', '/')
      if (r !== '/') {
        push(r)
      }
    }
    // console.log(apiKey)
    // eslint-disable-next-line
  }, [apiKey])

  useEffect(() => {
    // console.log(graphKey)
  }, [graphKey])

  useEffect(() => {
    if (width <= 900) {
      setIsMobile(true)
    }

    if (width > 900) {
      setIsMobile(false)
    }
  }, [width])

  useEffect(() => {
    document
      .querySelector(':root')
      .style.setProperty('--vh', window.innerHeight / 100 + 'px')
  }, [height])

  return (
    <ThemeProvider theme={theme}>
      {account && (
        <Header mobMenuOpen={mobMenuOpen} setMobMenuOpen={setMobMenuOpen} isMobile={isMobile} />
      )}
      <div style={{ display: 'flex', width: '100vw', height: 'calc(100 * var(--vh))' }}>
        {account && (
          <Sidebar graphProfile={graphProfile} logout={onSignOut} isMobile={isMobile} mobMenuOpen={mobMenuOpen} setMobMenuOpen={setMobMenuOpen} />
        )}
        <div
          {...css({
            width: '100%',
            height: '100%',
            zIndex: '99',
            overflowY: 'scroll',
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
            overflow: 'scroll',
            '::-webkit-scrollbar': {
              display: 'none'
            }
          })}
          id='scrollable'
        >
          {(!account && !loggingIn) && (
            <Login account={account} onSignIn={onSignIn} graphProfile={graphProfile} api={api} graph={graph} />
          )}
          {(!apiKey && loggingIn) && (
            <MoonLoader size='22px' color='#0067c6' css={{ margin: 'auto', marginTop: '150px' }} />
          )}

          {apiKey && (
            <Switch>
              <Route exact path='/opportunities/new'>
                <NewOpportunity isMobile={isMobile} user={user} graphProfile={graphProfile} api={api} graph={graph} />
              </Route>
              <Route exact path='/opportunities/:id/edit'>
                <NewOpportunity isMobile={isMobile} update user={user} graphProfile={graphProfile} api={api} graph={graph} />
              </Route>
              <Route path='/opportunities'>
                <Opportunities isMobile={isMobile} user={user} graphProfile={graphProfile} api={api} graph={graph} />
              </Route>
              <Route exact path='/'>
                <Redirect to='/opportunities' />
              </Route>

              <Route exact path='/share'>
                <Feed isMobile={isMobile} user={user} graphProfile={graphProfile} api={api} graph={graph} />
              </Route>
              <Route exact path='/share/:id'>
                <Feed isMobile={isMobile} user={user} graphProfile={graphProfile} api={api} graph={graph} />
              </Route>
            </Switch>
          )}

        </div>
      </div>

    </ThemeProvider>

  )
}

export default AuthProvider(App)
