import React, { useCallback, useEffect, useRef, useState } from 'react'
import api from '../../utils/api'
// import MoonLoader from 'react-spinners/MoonLoader'
import OpportunityMap from './OpportunityMap'
import OpportunityPanel from './OpportunityPanel'
import { useHistory, useLocation } from 'react-router-dom'
import { Button } from '@costain/react-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faMap } from '@fortawesome/free-solid-svg-icons'
import LogTimeModal from './LogTimeModal'

function Opportunities ({ graph, graphProfile, isMobile, user }) {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const st = params.get('startDate')
  console.log()

  const [opportunities, setOpportunities] = useState([])
  const [loading, setLoading] = useState(true)
  // eslint-disable-next-line
  const [hasMore, setHasMore] = useState(true);
  // eslint-disable-next-line
  const [search,setSearch] = useState('');
  const [page, setPage] = useState(0)
  const [searchError, setSearchError] = useState(false)
  const [hovering, setHovering] = useState(null)
  const [queryActivities, setQueryActivities] = useState('')
  const [queryPhysical, setQueryPhysical] = useState(true)
  const [queryOnline, setQueryOnline] = useState(true)
  const [queryStart, setQueryStart] = useState(null)
  const [queryEnd, setQueryEnd] = useState(null)
  const [queryComplete, setQueryComplete] = useState(false)
  const [queryUpcoming, setQueryUpcoming] = useState(false)
  const [queryBounds, setQueryBounds] = useState(null)
  const [center, setCenter] = useState(null)
  const [zoom, setZoom] = useState(null)
  const [myOpsOpen, setMyOpsOpen] = useState(false)
  const [myOps, setMyOps] = useState([])
  const [mobMap, setMobMap] = useState(false)
  const { push } = useHistory()
  const [lastSearch, setLastSearch] = useState(null)
  const [selectedOpportunity,setSelectedOpportunity] = useState(null)

  function useDidUpdateEffect(fn, inputs) {
    const didMountRef = useRef(false);
    useEffect(() => {
      console.log(didMountRef)
      if (didMountRef.current)
        return fn();
      else
        didMountRef.current = true;
    }, inputs);
  }

  const dateToString = (date) => {
    return `${date.year}-${date.month < 10 ? `${0}${date.month}` : date.month}-${date.day < 10 ? `${0}${date.day}` : date.day}`
  }

  const getOwnOps = async () => {
    try {
      const x = await api.get('/opportunities/user')
      const { data } = x
      setMyOps(data)
      console.log(data)
      return data
    } catch (e) {
      console.log('ERROR')
    }
    return []
  }



  useEffect(() => {
    if (myOpsOpen) {
      getOwnOps()
    }
  }, [myOpsOpen])

  useDidUpdateEffect(() => {
    fetchOpportunities()
  },[page, search, queryActivities, queryOnline, queryPhysical, queryStart, queryEnd, queryUpcoming, queryComplete, queryBounds])

  const fetchOpportunities = async() => {
    setSelectedOpportunity(null)
    setLoading(true)
    let opportunitiesReqString = `/opportunities?page=${page}&status=LIVE`
    let postcodeLookup = false
    if (search && search.length > 0) {
      const postcodeRegex = new RegExp( /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/)
      const s = search.replace(/\s+/g, '').trim().toUpperCase()
      if (postcodeRegex.test(s)) {
        postcodeLookup = true
      }
      setOpportunities([])
      opportunitiesReqString += `&search=${search}`
    }

    if (queryActivities.length > 0) {
      setOpportunities([])
      opportunitiesReqString += `&activities=${queryActivities}`
    }

    if (!queryPhysical) {
      opportunitiesReqString += `&physical=${queryPhysical}`
    }
    if (!queryOnline) {
      opportunitiesReqString += `&online=${queryOnline}`
    }
    if (queryStart) {
      opportunitiesReqString += `&startDate=${dateToString(queryStart)}`
    }

    if (queryEnd) {
      opportunitiesReqString += `&endDate=${dateToString(queryEnd)}`
    }

    if (queryComplete) {
      opportunitiesReqString += `&completed=${queryComplete}`
    }

    if (queryUpcoming) {
      opportunitiesReqString += `&upcoming=${queryUpcoming}`
    }

    if (queryBounds) {
      const boundsString = `${queryBounds.ne.lat},${queryBounds.ne.lng},${queryBounds.nw.lat},${queryBounds.nw.lng},${queryBounds.se.lat},${queryBounds.se.lng},${queryBounds.sw.lat},${queryBounds.sw.lng}`
      opportunitiesReqString += `&bounds=${boundsString}`
    }

    if (`?${opportunitiesReqString.split('?')[1]}` !== lastSearch) {
      push({ search: `?${opportunitiesReqString.split('?')[1]}` })
    } else {
      return setLoading(false)
    }

    setLastSearch(`?${opportunitiesReqString.split('?')[1]}`)

    let x
    try {
      console.log(opportunitiesReqString)
      x = await api.get(opportunitiesReqString)
      const { data } = x
      setLoading(false)
      const { results, total } = data
      console.log(opportunities)
      console.log(results)
      if (postcodeLookup) {
        const newCenter = results[0].activityLocation.location.coordinates
        setCenter([newCenter[1], newCenter[0]])
        setZoom(10)
      }
      setOpportunities(results)
      console.log(opportunities.concat(results).length)
      if (opportunities.concat(results).length < total) {
        setHasMore(true)
      } else {
        setHasMore(false)
      }
      setSearchError(false)
      setLoading(false)
      return results
    } catch (e) {
      setLoading(false)
      setSearchError(true)
      console.log(e)
    }
  }

  // eslint-disable-next-line
  const handleChange = useCallback(async (x, setter, key) => {
    setOpportunities([])
    setPage(0)
    const z = x.map((i) => {
      if (i.key === key) {
        if (i.active === true) {
          i.active = false
          return i
        }
        i.active = true
      } else {
        i.active = false
      }
      return i
    })

    setter(...[z])
  }, [])

  return (

    <>
      <div
        style={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <LogTimeModal isMobile={isMobile}/>

        <>
          {isMobile && (
            <div style={{ position: 'absolute', zIndex: '999999', bottom: 0, left: 0 }}>
              <Button active={false} onClick={() => setMobMap(!mobMap)} style={{ width: '64px', maxHeight: '64px', backgroundColor: 'white', borderRadius: '100%', height: '64px', padding: '0px', marginLeft: '16px', marginBottom: '32px', border: '1px solid #E8E8E9', boxShadow: '0px 2px 28px #E8E8E9' }} title='' icon={<FontAwesomeIcon color='rgba(101, 97, 125, 0.8)' icon={mobMap ? faList : faMap} />} />
            </div>
          )}
          <OpportunityPanel
            setMobMap={setMobMap}
            mobMap={mobMap}
            user={user}
            myOpsOpen={myOpsOpen}
            setMyOpsOpen={setMyOpsOpen}
            myOps={myOps}
            isMobile={isMobile}
            setCenter={setCenter}
            setZoom={setZoom}
            queryStart={queryStart}
            setQueryStart={setQueryStart}
            queryEnd={queryEnd}
            setQueryEnd={setQueryEnd}
            queryOnline={queryOnline}
            setQueryOnline={setQueryOnline}
            setQueryPhyiscal={setQueryPhysical}
            queryPhyiscal={queryPhysical}
            queryActivities={queryActivities}
            setQueryActivities={setQueryActivities}
            queryUpcoming={queryUpcoming}
            setQueryUpcoming={setQueryUpcoming}
            queryComplete={queryComplete}
            setQueryComplete={setQueryComplete}
            hovering={hovering}
            setHovering={setHovering}
            searchError={searchError}
            loading={loading}
            search={search}
            setSearch={setSearch}
            opportunities={opportunities}
            setSelectedOpportunity={setSelectedOpportunity}
          />

          <OpportunityMap
            mobMap={mobMap}
            selectedOpportunity={selectedOpportunity}
            myOps={myOps}
            myOpsOpen={myOpsOpen}
            mapZoom={zoom}
            setMapZoom={setZoom}
            mapCenter={center}
            setMapCenter={setCenter}
            hovering={hovering}
            setHovering={setHovering}
            opportunities={opportunities}
            setQueryBounds={setQueryBounds}
            center={[53.8008, -1.5491]}
            zoom={12}
          />

        </>

      </div>

    </>

  )
}

export default Opportunities
