import React, { useEffect, useState } from 'react'
// import MoonLoader from 'react-spinners/MoonLoader'
import OpportunityMap from './../Opportunities/OpportunityMap'
import FeedPanel from './FeedPanel'
import getUserPicture from '../../utils/getUserPicture'
import { Button } from '@costain/react-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faMap } from '@fortawesome/free-solid-svg-icons'
import { useHistory, useParams } from 'react-router-dom'

function Feed ({
  user,
  graphProfile,
  api,
  graph,
  isMobile
}) {
  const [opportunities, setOpportunities] = useState([])
  const [activities, setActivities] = useState([])
  const [hovering, setHovering] = useState(null)
  const [loading, setLoading] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [center, setCenter] = useState([53.8008, -1.5491])
  const [zoom, setZoom] = useState(7)
  const [selectedOpportunity, setSelectedOpportunity] = useState(null)
  const [hasMoreData, setHasMoreData] = useState(true)
  const [reqOpportunityId, setReqOpportunityId] = useState(0)
  const [feedPage, setFeedPage] = useState(0)
  const [selectedOpPage, setSelectedOpPage] = useState(0)
  const [mobMap, setMobMap] = useState(false)
  const { push } = useHistory()
  const { id } = useParams()

  const getProfilePicture = async (activity) => {
    var pic = null
    if (activity && activity.createdBy && activity.createdBy.adId) {
      pic = await getUserPicture({ id: activity.createdBy.adId }, api)
    } else {
      //! no pic
      //! TODO - probably a circle with initials?
      console.log('no pic')
    }

    return pic
  }

  const backToFirstPage = async (opportunityId) => {
    // console.log('call backToFirstPage');
    if (opportunityId) {
      setReqOpportunityId(opportunityId)
    } else {
      setReqOpportunityId(0)
    }
    setFeedPage(0)
    setActivities([])
    setHasMoreData(true)
    push('/share')
  }

  const getActivities = async (opportunity) => {
    setLoading(true)
    let ac = []
    let page
    if (opportunity || id) {
      setActivities([])
      page = selectedOpPage
      push(`/share/${opportunity ? opportunity._id : id}`)
    } else {
      page = feedPage
      push('/share')
    }
    let activitiesReqString = (opportunity || id) ? `/activities/opportunity/${opportunity ? opportunity._id : id}` : '/feed'
    if (page > 0) {
      ac = activities
    }
    const limit = 10
    activitiesReqString += `?page=${page}&limit=${limit}`
    console.log('activitiesReqString', activitiesReqString)
    const { data } = await api.get(activitiesReqString)
    console.log(data)
    const results = data.results
    const total = data.total
    if (!selectedOpportunity && id) {
      const { data } = await api.get(`/opportunities/${id}`)
      console.log(data)
      setSelectedOpportunity(data)
    }
    const resultsWithProfilePhoto =
      await Promise.all(
        results.map(
          async (activity) => {
            const profilePicture = await getProfilePicture(activity)
            activity.profilePicture = profilePicture
            return activity
          }))

    setActivities([...ac, ...resultsWithProfilePhoto])
    if (ac.concat(results).length < total) {
      setHasMoreData(true)
    } else {
      setHasMoreData(false)
    }
    setLoading(false)

    if (opportunity && results[0] && results[0].opportunity.activityLocation) {
      setCenter([results[0].opportunity.activityLocation.location.coordinates[1], results[0].opportunity.activityLocation.location.coordinates[0]])
      setZoom(15)
    }
  }

  const fetchMoreData = () => {
    console.log('fetchMoreData')
    getActivities(reqOpportunityId)
  }

  useEffect(() => {
    getActivities(selectedOpportunity)
    // eslint-disable-next-line
  },[feedPage, selectedOpPage, selectedOpportunity])

  useEffect(() => {
    if (graphProfile) {
      const getOpportunities = async () => {
       const x = activities.map(activity => {
         if (activity.opportunity) {
           activity.opportunity.filterType = "ACTIVITY"
           return activity.opportunity
         }
         return activity
       })
        setOpportunities(x)
      }
      getOpportunities()
    }

    // eslint-disable-next-line
  },[graphProfile, activities]);

  return (

    <>
      {isMobile && (
        <div style={{ position: 'absolute', zIndex: '999999', bottom: 0, left: 0 }}>
          <Button onClick={() => setMobMap(!mobMap)} style={{ width: '64px', maxHeight: '64px', backgroundColor: 'white', borderRadius: '100%', height: '64px', padding: '0px', marginLeft: '16px', marginBottom: '32px', background: '#E8E8E9', border: '1px solid #E8E8E9', boxShadow: '0px 2px 28px #E8E8E9' }} title='' icon={<FontAwesomeIcon color='rgba(101, 97, 125, 0.8)' icon={mobMap ? faList : faMap} />} />
        </div>
      )}
      <div
        style={{
          alignItems: 'center',
          display: 'flex'
        }}
      >

        <>
          {!mobMap && (
            <div style={{ width: isMobile ? '100%' : '60%', height: '100vh', boxShadow:'15px 4px 20px 0px rgb(133 133 133 / 20%)',zIndex:999 }}>
              <FeedPanel
                isMobile={isMobile}
                getActivities={getActivities}
                user={user}
                graphProfile={graphProfile}
                api={api}
                graph={graph}
                opportunities={opportunities}
                hovering={hovering}
                setHovering={setHovering}
                searchText={searchText}
                setSearchText={setSearchText}
                loading={loading}
                setCenter={setCenter}
                setZoom={setZoom}
                activities={activities}
                setActivities={setActivities}
                selectedOpportunity={selectedOpportunity}
                setSelectedOpportunity={setSelectedOpportunity}
                fetchMoreData={fetchMoreData}
                page={selectedOpportunity ? selectedOpPage : feedPage}
                setPage={selectedOpportunity ? setSelectedOpPage : setFeedPage}
                hasMoreData={hasMoreData}
                backToFirstPage={backToFirstPage}
              />
            </div>
          )}

          {(!isMobile || mobMap) && (
            <OpportunityMap
              markerClick={setSelectedOpportunity}
              hovering={hovering}
              setHovering={setHovering}
              selectedOpportunity={selectedOpportunity}
              opportunities={opportunities}
              mapCenter={center}
              mapZoom={zoom}
            />

          )}

        </>

      </div>

    </>

  )
}

export default Feed
