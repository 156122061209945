import React, { useRef } from 'react'
import './mention-style.css'
import OpportunityPost from './OpportunityPost'
import ActivityPost from './ActivityPost'

const Activity =
({
  activity,
  activities,
  setActivities,
  handleHovering,
  api,
  index,
  setSelectedOpportunity,
  user,
  reply,
  setReply,
  graph,
  isMobile
}) => {

  const ref = useRef(null)

  return (
    <>
      {(activity && ((activity.filterType === 'EVENT'))) && (
        <OpportunityPost complete={false} setSelectedOpportunity={setSelectedOpportunity} opportunity={activity.opportunity}  handleHovering={handleHovering} index={index}  />
      )}
      {(activity && ((activity.filterType === 'ACTIVITY'))) && (
        <ActivityPost handleHovering={handleHovering}  user={user} reply={reply} setReply={setReply} graph={graph} isMobile={isMobile} api={api} activities={activities} activity={activity} setActivities={setActivities}  ref={ref} key={index}/>
      )}
      {(activity && ((activity.filterType === 'OPPORTUNITY'))) && (
        <OpportunityPost complete={true} setSelectedOpportunity={setSelectedOpportunity} opportunity={activity}  handleHovering={handleHovering} index={index}  />
      )}
    </>
  )
}

export default Activity
