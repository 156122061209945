/* global localStorage */
/* global Blob */

import React, { Component } from 'react'
import {
  GRAPH_ENDPOINTS,
  GRAPH_REQUESTS,
  msalApp,
  requiresInteraction
} from './utils/auth'
import { WindowUtils } from 'msal'
import api from './utils/api'
import graph from './utils/graph'

const useRedirectFlow = true

export default C =>
  class AuthProvider extends Component {
    constructor (props) {
      super(props)
      this.state = {
        account: null,
        error: null,
        emailMessages: null,
        graphProfile: null,
        apiKey: null,
        graphKey: null,
        user: null,
        loggingIn: false,
        admins: []
      }

      this.handleSignIn = this.handleSignIn.bind(this)

      api.interceptors.request.use(async (config) => {
        if (!this.state.loggingIn) {
          try {
            const apiToken = await this.acquireToken(GRAPH_REQUESTS.API, useRedirectFlow)
            if (apiToken) {
              config.headers.Authorization = `Bearer ${apiToken.accessToken}`
              this.setState({ apiKey: apiToken.accessToken })
            }
            return Promise.resolve(config)
          } catch (e) {
            console.log(e)
            return Promise.resolve(config)
          }
        }
      })

      graph.interceptors.request.use(async (config) => {
        if (!this.state.loggingIn) {
          try {
            const graphToken = await this.acquireToken(GRAPH_REQUESTS.LOGIN, useRedirectFlow)
            if (graphToken) {
              config.headers.Authorization = `Bearer ${graphToken.accessToken}`
            }
            return Promise.resolve(config)
          } catch (e) {
            console.log(e)
            return Promise.resolve(config)
          }
        }
      })
    }

    async acquireToken (request, redirect, email, sid) {
      return msalApp.acquireTokenSilent({ ...request, loginHint: email, sid }).catch(error => {
        if (requiresInteraction(error.errorCode)) {
          console.log('The email is: ' + email)
          console.log('The sid is: ' + sid)
          msalApp.acquireTokenRedirect({
            ...request,
            redirectUri: process.env.REACT_APP_DEPLOY_URL,
            loginHint: email,
            sid
          })
        } else {
          console.error('Non-interactive error:', error)
        }
      })
    }

    async handleSignIn ({ redirect, email }) {
      this.setState({ loggingIn: true })
      localStorage.setItem('redirect', redirect)
      console.log('REDIRECT: ' + redirect)
      console.log('EMAIL: ' + email)
      const acc = await msalApp.getAccount()
      if (!acc) {
        console.log('no account so redirect to login')
        const login = await msalApp.loginRedirect({
          ...GRAPH_REQUESTS.LOGIN,
          redirectUri: `${process.env.REACT_APP_DEPLOY_URL}`,
          loginHint: email
        })
        this.setState({ loggingIn: false })
        return login
      }
    }

    onSignOut () {
      msalApp.logout()
    }

    async componentDidMount () {
      msalApp.handleRedirectCallback(error => {
        if (error) {
          const errorMessage = error.errorMessage ? error.errorMessage : 'Unable to acquire access token.'
          // setState works as long as navigateToLoginRequestUrl: false
          this.setState({
            error: errorMessage
          })
        }
      })

      const account = msalApp.getAccount()
      this.setState({
        account
      })
      if (account && !WindowUtils.isInIframe()) {
        this.setState({ loggingIn: false })
        // console.log(account)
        try {
          const { data } = await api.get(`/user/${account.accountIdentifier}`)
          this.setState({ user: data })
        } catch (e) {
          console.log(e)
        }

        let graphProfile
        try {
          const x = await graph.get(GRAPH_ENDPOINTS.ME)
          graphProfile = x.data
        } catch (e) {
          console.log(e)
        }

        let graphPhoto
        try {
          graphPhoto = await graph.get(GRAPH_ENDPOINTS.ME + '/photo/$value', {
            responseType: 'blob'
          })
          const blob = new Blob([graphPhoto.data])
          graphProfile.pic = URL.createObjectURL(blob)
        } catch (e) {
          console.error(e)
        }
        this.setState({
          graphProfile
        })
      }
    }

    render () {
      return (
        <C
          {...this.props}
          account={this.state.account}
          emailMessages={this.state.emailMessages}
          error={this.state.error}
          graphProfile={this.state.graphProfile}
          user={this.state.user}
          onSignIn={this.handleSignIn}
          onSignOut={() => this.onSignOut()}
          apiKey={this.state.apiKey}
          graphKey={this.state.graphKey}
          api={api}
          graph={graph}
          loggingIn={this.state.loggingIn}
        />
      )
    }
  }
